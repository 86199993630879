import React from 'react'
import LayoutComponent from '../LayoutComponent';
import"../CounterChecking/Counter.css"

export default function MailToCoordinator() {
 
  return (


    <LayoutComponent>
    <div>
      <div className='container-fluid pb-3 bg-light confirmationBoooking-main-div'>
        <div className='row'>
          <div className='col-sm-6 mt-4'>
            <p className='mt-2 ms-2 fs-4 fw-700'>Appointment Booking : <span className='fw-bold'>Book an Appointment</span> </p>
          </div>
        </div>
        <div className='container bg-white p-5' >
          <div className='mt-5'>
          <h1 className='congratulationNew  text-center'>Request For Preferred Slot</h1>
          </div>
          {/* <h3 className=' congratulationNew text-center mt-1'>Your Payment is Sucessfull</h3>  */}
          <div className='row ms-2 ms-sm-0 gap-3 fw-600 d-flex mb-5' style={{textAlign:"center"}}>
            <h5>Your request is sent to the event coordinator, Our team will reach out to you at the earliest possible to fix an appointment with the Appointment Booking.</h5>
         
           

          </div>
     

        </div>
      </div>


    </div>
    
    </LayoutComponent>
  )
}