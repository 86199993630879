import React, { useEffect, useMemo, useState, useContext } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { UserContext } from '../../App';
import Services from '../network/Services';
import konsole from '../network/konsole';
import { EventBaseUrl, CounterBaseUrl, CounterMobileBaseUrl, AoAdminBaseUrl, AoPartnerBaseUrl, AoAcaddemyUrl, IntakeEntryBaseUrl, lpoiceLink, AoAgenturl, intakeBaseUrl, lpoLiteUrl } from '../control/Constant';
const AccountRoleChange = () => {
    const globalData = useContext(UserContext)
    const { loggedInUserRoles, setLoggedInUserRoles, setLoader } = globalData;
    const [isAccountRoleChange, setIsAccountRoleChange] = useState(false)
    const [selectedRoleId, setSelectedRoleId] = useState('');
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (loggedInUserRoles?.length == 0) {
            fetchRole();
        }
    }, [])

    const fetchRole = () => {
        let stateObj = JSON.parse(sessionStorage.getItem('stateObj'));
        Services.getUserDetailsByUserEmailId({ userId: stateObj.userId })
            .then((res) => {
                konsole.log("response", res);
                setLoader(false);
                if (res?.data?.length > 0) {
                    const resData = res.data[0];
                    const userRoles = resData?.roleDetails?.filter((item) => item.roleId != stateObj.roleId && item?.roleId != 2 && item?.roleId != 20 & item.roleId != 9 && item.roleId != 7 && item.roleId != 8 && item.roleId != 12 && item.roleId != 19 && item.roleId != 17 && item.roleId != 18)?.map((i) => ({ ...i, value: i.roleId, label: i.roleName }));
                    konsole.log("userRoles", userRoles);
                    setLoggedInUserRoles(userRoles);
                }
            })
            .catch((err) => {
                setLoader(false);
                konsole.log("Error", err);
            });
        konsole.log('loggedInUserRoles', loggedInUserRoles)
    }

    const handleSelectChange = (e) => {
        let value = e.target.value;
        setSelectedRoleId(value);
        setIsError(false)
    }
    const handleOpen = (val) => {
        setIsAccountRoleChange(val)
    }
    function getRoleLabel(roleId) {
        const urlMap = {
            '1': intakeBaseUrl + 'login',
            '3': intakeBaseUrl,
            '4': AoAdminBaseUrl,
            '5': AoAgenturl,
            '6': CounterBaseUrl,
            '8': CounterBaseUrl,
            '10': intakeBaseUrl,
            '11': AoAdminBaseUrl,
            '13': intakeBaseUrl,
            '14': intakeBaseUrl,
            '15': intakeBaseUrl,
            '16': CounterMobileBaseUrl,
            '21': IntakeEntryBaseUrl
        };
        return urlMap[roleId] || '';
    }

    const handleContinue = () => {
        if (!(selectedRoleId != undefined && selectedRoleId != null && selectedRoleId != '')) {
            setIsError(true);
            return;
        }
        let stateObj = JSON.parse(sessionStorage.getItem('stateObj'));
        let tokenKey = `appState=${stateObj?.appState}&userId=${stateObj.userId}&roleId=${selectedRoleId}&loggenInId=${stateObj?.loggenInId}`;
        let roleUrl = getRoleLabel(selectedRoleId);
        konsole.log("roleUrlroleUrl",roleUrl,stateObj.roleId)
        let redirectUrl = `${roleUrl}?token=${window.btoa(tokenKey)}`
        // window.open(redirectUrl, "_blank");
        sessionStorage.clear();
        window.location.replace(redirectUrl);

    }
    // @@ konsole
    konsole.log("loggedInUserRoles", loggedInUserRoles)
    return (
        <>
            {(loggedInUserRoles.length > 0) &&
                <>
                    <button className='custom-button-account-change' onClick={() => handleOpen(true)} >
                        Switch Account
                    </button>
                    <Modal show={isAccountRoleChange} onHide={() => handleOpen(false)} >
                        <Modal.Header closeButton style={{ border: "none" }}> </Modal.Header>
                        <Modal.Body>
                            <div className='body'>
                                <div className="">
                                    <label for="formGroupExampleInput2" className="form-label label-name-color fs-5">Please pick a role for this visit</label>
                                    <div className='col-md-12 form-group'>
                                        <select onChange={(e) => handleSelectChange(e)} value={selectedRoleId} id="uniqueDropdown" className=" form-select p-1 bg-light fs-7 selectpicker  bg-light dropdown-item  border  fs-6" placeholder="Select">
                                            <option value="">Select</option>
                                            {loggedInUserRoles.map((item, index) => {
                                                return <option value={item.value}> {item.label}</option>
                                            })}
                                        </select>
                                        {(isError == true) && <p className="text-danger">Please select a role</p>}
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
                            <Button style={{ backgroundColor: '#720c20' }} onClick={() => handleContinue()} >
                                Continue
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            }
        </>
    )
}

export default AccountRoleChange
