import React, { useState, useRef, useEffect, useContext } from 'react';
import konsole from '../network/konsole';
import commonLib from '../control/commonLib';
import LayoutComponent from "../LayoutComponent";
import './Congratulations.css';
import { UserContext } from '../../App';
import Services from '../network/Services';
import { CounterCheckIn, CounterWalkIn ,ConstantEmail,leadGetApi,leadCommonApi} from '../control/Constant';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import occurrenceId from '../control/occurrence';
import Notification from '../Notification';

const Congratulations = (props) => {
    const [sheet, setSheet] = useState([])
    const bookedSeats = (props.location.state !== undefined) ? props.location.state : "";
    const OrderId = props.location.search;


    konsole.log("locations", props.location.state)
    const history = useHistory();
    const seminarList3 = JSON.parse(commonLib.getSessionStoarge('seminarList3'));
    const seminarObj = commonLib.getObjFromStorage('seminarObj');
    const GetResponse = commonLib.getObjFromStorage('GetResponse');
    const venueObj = commonLib.getObjFromStorage('venueObj');
    const attendeeObj = commonLib.getObjFromStorage('attendeeObj');
    const userLoggedInDetail = commonLib.getObjFromStorage('userLoggedInDetail');
    const Location = commonLib.getObjFromStorage("Location");
    const paymentSuccesStatus = commonLib.getQueryParameters(OrderId, 'TrxnStatus')
    const { setLoader } = useContext(UserContext);
    const stateObj = commonLib.getObjFromStorage("stateObj");
    const selfCheckData = commonLib.getObjFromStorage("selfCheckData");
    const [showModal , setShowModal] = useState(false)
    const [warning, setWarning] = useState()
    const [Color1, setColor1] = useState("")
    const toDate =  seminarList3.remarks.slice(0, 11);
    const toTime =  seminarList3.remarks.slice(11, 31);
    const weekDay = seminarList3.remarks.slice(20, 40)
    const data1 = (seminar, toDate) => {
      var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      var d = new Date(seminar);
      var dayName = days[d.getDay()];
      var d2 = new Date(toDate);
      var dayName2 = days[d2.getDay()];
      return "(" + dayName + "-" + dayName2 + ")"
  }

    // konsole.log("paymentSuccesStatus", (sheet[0]?.allottedSeats.length === undefined) || (sheet[0]?.allottedSeats.length === 0) || (sheet[0]?.allottedSeats.length === null) || (sheet[0]?.allottedSeats.length === ""))
    const add3Hours2 = (time) => {
        // konsole.log("kl", time)
        let newTime = new Date(time);
        newTime.setHours(newTime.getHours() - 3);
        return newTime;
    }
    const userActivateIP = (seminarAttendeeId)=>{
        // alert("yes")
        setLoader(true);
           const promises = Services.putUserActivate1(seminarAttendeeId);
             konsole.log("resp111",seminarAttendeeId)
            promises.then(res => {
                konsole.log("putapi2222",res)
                konsole.log("saddasdsadasdasd",res)
                // let responseData = res.data.data.attendees[0]
               
       

                }).catch(err => {
                // alert(err.response.data.messages[0])
                konsole.log("errsw111", err.response);
                // Toaster.error(err.response.data.messages[0])
            }).finally(fin => {
                setLoader(false);
                konsole.log("finish");
            })
    }
    // useEffect(() => {
    //     const bookingId = commonLib.getObjFromStorage("classbookingId");
    //     const semid = GetResponse?.data1?.seminarAttendeeId
    //     let subtenantId = sessionStorage.getItem('subtenantId')
    //     if(subtenantId == 745){
    //    classbookingNew(bookingId,true,GetResponse?.data1?.userId,"classBooking",semid)
    //     }
    
      
    // }, [])
    
    // const classbookingNew = (bookingId,isActive,updatedBy,remarks,classroomAttendeeId)=>{
      
    //     const promise = Services. putClassBooking (bookingId,isActive,updatedBy,remarks,classroomAttendeeId)
    //     konsole.log("rrgisfggg",bookingId,isActive,updatedBy,remarks,classroomAttendeeId)
    //       promise.then((res) => {
    //         console.log("restigff",res)
       
       
    //   })
    //       .catch(err => {
    //         console.log("errfdddd", err);
    //         // setShowModal(true)
    //         // setWarning(err.response.data.messages[0])
    //         //     setColor1("#FA113D")
    //         // ////alert(err.response.data.messages[0])
            
            
    //       }).finally(fin => {
    //         // konsole.log("finish", fin);
    //         // setLoader(false);
    //       })
    
    //   }

    useEffect(() => {

        
        konsole.log("allDataallData", bookedSeats)
        let allData
        if(bookedSeats !== undefined && bookedSeats !== "" && bookedSeats !== null && bookedSeats !== "Cash"){
            
            let seatData = [];
            for(let i =0; i < bookedSeats?.data1?.length; i++){
               seatData.push(bookedSeats?.data1[i]?.seatNo)
            }

            let finalSeatNumber = seatData?.toString()

                     
    
    
      allData = {
                emailId: GetResponse?.userRegistration?.emailAddress,
                mobileno: `${GetResponse?.userRegistration?.countryCode}${GetResponse?.userRegistration?.phoneNumber}`,
                seminarAttendeeId: bookedSeats?.data1[0]?.seminarAttendeeId,
                seatNo: finalSeatNumber

              }
            }
            
        let subtenantId = sessionStorage.getItem('subtenantId')
        // if(paymentSuccesStatus === "SUCCESS" || props.location.state === "Cash"){
        //     if(selfCheckData === "Yes"){
        if (paymentSuccesStatus === "SUCCESS" || props.location.state === "Cash") {
            userActivateIP(GetResponse?.data1.seminarAttendeeId)
            if (selfCheckData === "Yes") {
                // let date1 = moment(seminarObj?.seminarDate).format('YYYY-MM-DD')
                // let time1 = moment(seminarObj?.seminarTime, "HH:mm:SS").format("h:mm A")
                // let momentObj = moment(date1 + time1, 'YYYY-MM-DDLT');
                // let dateTime = momentObj.format('YYYY-MM-DD HH:mm:ss');
                // let currentTime = new Date();
                // let endingTime = add3Hours1(dateTime);
                // let startTime = add3Hours2(dateTime);
                // if (currentTime < startTime) {
                //     setWarning("Check-in for attendees is enabled 3 hours before the seminar time.")
                //     setColor1("#ffcc00")
                //     setShowModal(true)
                  
                // }
                // else {
            
            handleWalkInCheckInUser(GetResponse?.data1.seminarId, GetResponse?.data1.seminarAttendeeId, GetResponse?.data1?.userId, 4, GetResponse?.data1?.userId)
    //    }
    }
    else{
        allData = {
            emailId: GetResponse?.userRegistration?.emailAddress,
            mobileno: `${GetResponse?.userRegistration?.countryCode}${GetResponse?.userRegistration?.phoneNumber}`,
            seminarAttendeeId: GetResponse?.data1?.seminarAttendeeId,
            firstName : GetResponse?.userRegistration?.firstName,
            seatNo: ""
          }
      
        
        // if(allData?.emailId !== `${GetResponse?.userRegistration?.firstName}${ConstantEmail}`){
            occurrenceId.callOccurrenceIdFunc(1,subtenantId, allData)
        // }
          
        
            let coordinatorEmailIData = commonLib.getObjFromStorage('seminarList3')?.coordinators
                konsole.log("coordinatorEmailIData",coordinatorEmailIData)
                for(let i=0; i <coordinatorEmailIData.length; i++){
                    konsole.log("coordinatorEmailIData11",coordinatorEmailIData[i])
                  let allData1 = {
                        coordinatorEmailID: userLoggedInDetail?.primaryEmailId,
                        coordinatorCellNumber: userLoggedInDetail?.cellNo,
                        seminarAttendeeId: GetResponse?.data1?.seminarAttendeeId,
                        seatNo: ""
                      }
                    
                    // occurrenceId.callOccurrenceIdFunc(10,subtenantId, i)
                    occurrenceId.callOccurrenceIdFunc(10,subtenantId, allData1, i)
                }

       }
    }else{


        if(bookedSeats !== undefined && bookedSeats !== "" && bookedSeats !== null){
            
                     
        // occurrenceId.callOccurrenceIdFunc(2,subtenantId, allData)
    }else{
        allData = {
            emailId: GetResponse?.userRegistration?.emailAddress,
            mobileno: `${GetResponse?.userRegistration?.countryCode}${GetResponse?.userRegistration?.phoneNumber}`,
            seminarAttendeeId: GetResponse?.data1?.seminarAttendeeId,
            firstName : GetResponse?.userRegistration?.firstName,
            seatNo: ""
          }
        
            occurrenceId.callOccurrenceIdFunc(1,subtenantId, allData)
        
         
          let coordinatorEmailIData = commonLib.getObjFromStorage('seminarList3')?.coordinators
                konsole.log("coordinatorEmailIData",coordinatorEmailIData)
                for(let i=0; i <coordinatorEmailIData.length; i++){
                    
                    // occurrenceId.callOccurrenceIdFunc(10,subtenantId, i)
                    
                    occurrenceId.callOccurrenceIdFunc(10,subtenantId, allData, i)
                }
        //   setTimeout(()=>{

        //       occurrenceId.callOccurrenceIdFunc(10,subtenantId, allData)
        //     },2000)
        }
    }

     }, [])
    
   const handleWalkInCheckInUser = (seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy) => {
    let subtenantId = sessionStorage.getItem('subtenantId')
            setLoader(true);
            const promises = Services.updateSeminarAttendeeInfo(seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy);
             konsole.log("resp1",seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy)
            promises.then(res => {
                runFunctionsSequentially()
                let responseData = res.data.data.attendees[0]
                 let seatData = [];
                 for(let i =0; i < responseData?.allottedSeats.length; i++){
                    seatData.push(responseData?.allottedSeats[i]?.seatNo)
                 }

                 let finalSeatNumber = seatData?.toString()

                  let allData = {
            emailId: responseData?.emailAddress,
            mobileno: responseData?.mobileNo,
            seminarAttendeeId: responseData?.seminarAttendeeId,
            seatNo: finalSeatNumber,
          }

        //   occurrenceId.callOccurrenceIdFunc(2,subtenantId, allData)
                const selfWalkInCheckInObj = res.data.data?.attendees;
         
              setSheet(selfWalkInCheckInObj)

                    handleWalkInCheckInUser(GetResponse?.data1.seminarId, GetResponse?.data1.seminarAttendeeId, GetResponse?.data1?.userId, 4, GetResponse?.data1?.userId)
                }).catch(err => {
                // alert(err.response.data.messages[0])
                konsole.log("errsw", err.response);
                // Toaster.error(err.response.data.messages[0])
            }).finally(fin => {
                setLoader(false);
                konsole.log("finish");
            })
    }
    const runFunctionsSequentially = async () => {
        let data = GetResponse?.userRegistration
        // let tag1 = [`notattended,${data?.firstName} ${data?.lastName}`]
        let tag1 = [`not attended`]
        // let tags = [`attended,${data?.firstName} ${data?.lastName}`,`notbooked,${data?.firstName} ${data?.lastName}`]
        let tags = [`attended`,`not booked`]
        const result1 = await getLoggedInUserdata(data.emailAddress, `${data?.firstName} ${data?.lastName}`, "DELETE",tag1);
        if(result1 !== undefined && result1 !== "err"){
        const result2 = await getLoggedInUserdata(data.emailAddress, `${data?.firstName} ${data?.lastName}`, "POST",tags);
        }
    };
    const getLoggedInUserdata = async (email,name,method,tag) => {
        let json = {
                 email : email,
                 method : method,
                 tag : tag
             } 
          
        const result = await leadGetApi(json);
          if(result !== null && result !== undefined && result !== ""){
            const result2 = await leadCommonApi(result,method)
            return result2
          }
        if(result=='err') return;
    };

    const tryAgain = () => {
        history.push({
            pathname: '/venuePayment'
        })
    }

    return (

        <LayoutComponent>
                  <Notification showModal={showModal} message={warning} color={Color1} setShowModal={setShowModal}/>

            <div >
                {(paymentSuccesStatus == "SUCCESS" || props.location.state === "Cash" && seminarObj.isSeminarPaid == true || seminarObj.isSeminarPaid == false) ?
                    (<div className='bgclr Congratulations-screens-msg'>

                        <div className="container-fluid" >
                            <div className=' d-flex justify-content-between flex-column flex-md-row'>
                                <div className='mt-3' >
                                    {seminarObj?.subtenantId == 745 ?
                                    <h4>Workshop Booking: <b> Book a Workshop
                                        </b></h4>
                                     : <h4>Seminar Booking: <b> Book a Seminar</b></h4>
                                    }
                                    {/* // <h4>Seminar Booking: <b> Book a Seminar</b></h4> */}

                                  
                                    <p className='fs-5'>Topic : {seminarObj.seminarTopic}(
                                        {seminarObj.isSeminarPaid == true ? "Paid" : "Free"}
                                        {seminarObj.isSeminarPaid == true ? ": $" + seminarObj.seminarFee : ""}
                                        )</p>
                                </div>
                                <div className='mt-4'>
                                    {/* <h5>Venue: {Location?.seminarVenue}</h5> */}
                                    <h5>{Location?.seminarType !== "Direct" ?  "" : "Venue : " + Location?.seminarVenue}</h5>

                                    {/* <p className="bi bi-geo-alt form-label fs-6" style={{ color: "#720c20" }} >Location: {venueObj?.venueAddressLine1}</p> */}
                                    <p className="bi bi-geo-alt form-label fs-6" style={{ color: "#720c20" }} >
                  {Location?.seminarType !== "Direct" ?  "" : "Location : " + venueObj?.venueAddressLine1 }
                  {/* Location: {venueObj?.venueAddressLine1} */}
                  </p>
                  <p className=" bi bi-geo-alt form-label fs-6" style={{ color: "#720C20" }} >
              {seminarObj?.subtenantId == 745 ?
               (`${commonLib.getFormattedDate(seminarObj?.seminarDate)} To ${commonLib.getFormattedDate(toDate)} ${weekDay == 1 ? "(Sat-Sun)" : data1(seminarObj?.seminarDate, toDate, weekDay)}
               & ${commonLib.getFormattedTime(seminarObj?.seminarTime)} To ${commonLib.getFormattedTime(toTime)}`)
               : 
              `${commonLib.getFormattedDate(seminarObj?.seminarDate)} & ${commonLib.getFormattedTime(seminarObj?.seminarTime)}`}
              </p>
                                </div>
                            </div>
                        </div>
                        <div className=' ms-4 me-4 d-flex justify-content-center' >
                            <row className="d-flex w-100">
                                <div className="p-5 bg-white col-12 col-md-12 col-xl-12 rounded" style={{height:"100%"}}>
                                    <h1 className='fs-1 text-center CongratulationHeading fw-bold'>Congratulations</h1>
                                    <h3 className='text-center CongratulationHeading fw-bold'>You have Registered Sucessfully</h3>

                                    {(bookedSeats === "" || bookedSeats === "Cash") && ((sheet[0]?.allottedSeats.length === undefined) || (sheet[0]?.allottedSeats.length === 0) || (sheet[0]?.allottedSeats.length === null) || (sheet[0]?.allottedSeats.length === "")) ? "" :
                                        <></>
                                        // <div className='row mt-5'>
                                        //     {seminarObj?.subtenantId == 745 ? ("") : 
                                        //     <div className='col-10 sol-md-8 col-lg-6 m-auto  Congratulation-box d-flex align-items-center justify-content-center gap-3'>
                                            
                                        //         <row className="d-flex align-items-center">
                                        //             <div className="conOne ms-2"><img src="/icons/congratulationsLogo.svg" /></div>
                                        //             {/* {seminarObj.isSeminarPaid == true ? 
                                        //             <div className="conTwo fs-3 ms-2 me-2"><b>{

                                        //             sheet[0]?.allottedSeats?.length > 1 ? "Seats are" : "Seat is"
                                        //             } </b> </div> : 
                                        //             <div className="conTwo fs-3 ms-2 me-2"><b>{

                                        //                 bookedSeats.data1?.length > 1 ? "Seats are" : "Seat is"
                                        //                 } </b> </div>
                                        //             } */}


                                        //             <div className="vatical-line"></div>
                                        //             {/* <div className="conFour fs-2 ms-3 me-2 d-flex"><b> No: </b><strong>&nbsp;
                                        //                 {
                                        //                     seminarObj.isSeminarPaid == true ?
                                        //                         (sheet[0]?.allottedSeats !== undefined && sheet[0]?.allottedSeats !== "" ? sheet[0]?.allottedSeats?.map((seat, index) => {
                                        //                             return `${seat.seatNo}${(sheet[0]?.allottedSeats.length - 1 === index) ? '' : ', '}`
                                        //                         }) : ""

                                        //                         ) : (
                                        //                             bookedSeats.data1 !== undefined && bookedSeats.data1 !== "" ? bookedSeats.data1.map((seat, index) => {
                                        //                                 return `${seat.seatNo}${(bookedSeats.data1.length - 1 === index) ? '' : ', '}`
                                        //                             }) : ""

                                        //                         )

                                        //                 } </strong>
                                        //             </div> */}
                                                    
                                        //         </row>
                                        //     </div>}

                                        // </div>
                                    }
                                </div>
                            </row>
                        </div>

                    </div>)
                    : (paymentSuccesStatus == "FAILED") ?
                    
                    (<div className='contenArea container-fluid  d-flex justify-content-center bgclr Congratulations-screens-msg' style={{ height: 'auto', flexDirection: "column" }}>

                        <div className='mt-1' >
                            <h4>Seminar: <b> Payment Status</b></h4>

                            <p className='fs-5'>Topic : {seminarObj.seminarTopic}(
                                {seminarObj.isSeminarPaid == true ? "Paid" : "Free"}
                                {seminarObj.isSeminarPaid == true ? ": $" + seminarObj.seminarFee : ""}
                                )</p>
                        </div>

                        <div className=' container bg-white' >
                            <div className='text-center '>
                                <img className='mt-5' src='\imgpsh_fullsize_anim.png'></img>
                            </div>
                            <h1 className='congratulationNew text-center mt-3 fw-bold fs-1 pt-3' style={{ color: "#F2494B" }}>Payment is Failed</h1>

                            <div className='btm text-center mt-5 pb-4'>
                                <button type='text' className='theme-btn rounded text-center p-2' onClick={tryAgain}>
                                    Try Again
                                </button>

                            </div>

                        </div>
                    </div>) : 
                    (<div className='contenArea container-fluid  d-flex justify-content-center bgclr Congratulations-screens-msg' style={{ height: 'auto', flexDirection: "column" }}>

                    <div className='mt-1' >
                        <h4>Seminar: <b> Payment Status</b></h4>

                        <p className='fs-5'>Topic : {seminarObj.seminarTopic}(
                            {seminarObj.isSeminarPaid == true ? "Paid" : "Free"}
                            {seminarObj.isSeminarPaid == true ? ": $" + seminarObj.seminarFee : ""}
                            )</p>
                    </div>

                    <div className=' container bg-white' >
                        <div className='text-center '>
                            <img className='mt-5' src='\imgpsh_fullsize_anim.png'></img>
                        </div>
                        <h1 className='congratulationNew text-center mt-3 fw-bold fs-1 pt-3' style={{ color: "#F2494B" }}>Payment is Failed</h1>

                        {/* <div className='btm text-center mt-5 pb-4'>
                            <button type='text' className='theme-btn rounded text-center p-2' onClick={tryAgain}>
                                Try Again
                            </button>

                        </div> */}
                        {tryAgain()}

                    </div>
                </div>)
                }
            </div>
        </LayoutComponent>
    );
}

export default Congratulations;