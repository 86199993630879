import React from 'react'
import Footer from './Footer/Footer'
import Header from './Headers/Header'

function LayoutComponent({children, pathName}) {
  return (
    <div>
        <Header pathName={pathName}/>
            {children}
        <Footer />
    </div>
  )
}

export default LayoutComponent