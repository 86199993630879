import React,{useEffect,useState,useContext,useRef} from "react";
import LayoutComponent from "./LayoutComponent";
import commonLib from "./control/commonLib";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import {CounterWalkIn,leadGetApi,leadCommonApi,CounterPlanner,isNotValidNullUndefinedfile,capitalizeName} from './control/Constant';
import Notification from "./Notification";
import { AOLOGINBASEURL } from './network/url';
import Services from './network/Services';
import occurrenceId from "./control/occurrence";
import { UserContext } from "../App";
import konsole from "./network/konsole";
import "./CounterChecking/Counter.css"

export default function ConfirmBooking(props) {
  const [clientIpAddress, setClientIpAddress] = useState('');
  const { setLoader } = useContext(UserContext);
  const history = useHistory();
  const OrderId = props.location.search;
  const orderId = commonLib.getQueryParameters(OrderId, 'OrderId')
  const plannerDataRef = useRef();
  const paymentSuccesStatus = commonLib.getQueryParameters( OrderId, "TrxnStatus");
  const orderId1 = commonLib.getQueryParameters( OrderId, "OrderId");
  const seminarObj = commonLib.getObjFromStorage("seminarObj");
  const classroomObj = commonLib.getObjFromStorage("classroomStaus");
  const classroomObj1 = commonLib.getObjFromStorage("calssroom");
  const attendeeObj = commonLib.getObjFromStorage("attendeeObj");
  const classObj = commonLib.getObjFromStorage("GetClassroom");
  const selectedSlot = commonLib.getObjFromStorage("timeSlot");
  const stateObj = commonLib.getObjFromStorage("stateObj");
  const bookingId = commonLib.getObjFromStorage("classbookingId");
  const classOrderId = commonLib.getObjFromStorage("classOrderId");
  const subtenantId = commonLib.getObjFromStorage("subtenantId");
  const countRenderData = commonLib.getObjFromStorage("countRenderData");
  const userLoggedInDetail = commonLib.getObjFromStorage("userLoggedInDetail");
  const subtenatName = commonLib.getObjFromStorage("subtenatName");
  const isNotSlots = commonLib.getObjFromStorage("isNotSlots");
  const planningData = commonLib.getObjFromStorage("planningData");
  const uniquelink = `${AOLOGINBASEURL}account/Signin?subtenantId=${seminarObj.subtenantId}`
  const plannerCall = useRef();
  const finalData = useRef(false);
  const templeteId = useRef(false);
  const [showModal , setShowModal] = useState(false)
  const [warning, setWarning] = useState()
  const [Color1, setColor1] = useState("")
  const slotIsSelected = props.location.state



  useEffect(() => {
    if (countRenderData === true && classroomObj1 !== true) { 
      if (paymentSuccesStatus === "SUCCESS" || props.location.state?.paymentType == "Cash") {
        const seminarAttendeeId = attendeeObj.seminarAttendeeId;
        const upsertedBy = commonLib.getObjFromStorage("stateObj").userId
        const paymentStatusId = 1;
        const paymentStatusDate = new Date();
        const isActive = true;
        const planningId = planningData?.planningId
        let orderId1 = props.location?.state?.orderId ?? orderId
        upsertPlannerBooking(0, seminarAttendeeId, orderId1, paymentStatusId, paymentStatusDate, upsertedBy, isActive,planningId);
        commonLib.setDatatoSession("countRenderData", JSON.stringify(false))

      }

    }
   }, [])
  
   useEffect(() => {
    const cashPayment = props.location && props.location.state === "Cash";
    const successPayment = paymentSuccesStatus === "SUCCESS";
  
    if ((successPayment || cashPayment || paymentSuccesStatus === "FAILED") && classroomObj1 === true) {
        const isActive = successPayment || cashPayment;
        const paymentTrxnNo = (successPayment || paymentSuccesStatus === "FAILED") ? orderId1 : classOrderId;
        const paymentStatusId = isActive ? 1 : 2;
        const bookingStatusId = isActive ? 1 : 6;
        const updatedBy = stateObj?.userId;
        const remarks = "Paymentstatus";
        const bookingId1 = bookingId;
        paymentStatus(bookingId1, isActive, updatedBy, remarks, paymentTrxnNo, paymentStatusId, bookingStatusId);
    }
  
    if (successPayment || cashPayment) {
        if (classroomObj1 === true) {
            const userRegistration = {
                roleId: 2,
                noOfAttendee: 1,
                clientIPAddress: clientIpAddress,
                countryCode: '+1',
                semUserStatusId: CounterWalkIn,
                phoneNumber: classroomObj.seminar?.attendeeMobileNo,
                firstName: classroomObj.seminar?.firstName,
                emailAddress: classroomObj.seminar?.attendeeEmailAddress
            };
            registerNewUser(745, classroomObj.selectedUserObj?.seminarId, userRegistration);
        }
    }
  }, []);





  useEffect(() => {
    getIpAddress()
  },[]);

  const numberFormate = (number) => {
    const lastTenDigitArray = array => array.slice(-10);
      if(number != null && number !== undefined && number != ""){
        return commonLib.convertToUSFormat(lastTenDigitArray(number), "1")
      }else{
       
        return number
      }

  }
     const upsertPlannerBooking = (plannerAppId, seminarAttendeeId, billTrackNo, paymentStatusId, paymentStatusDate, upsertedBy, isActive,planningId) => {
    setLoader(true);
    const promises = Services.upsertPlannerBooking(plannerAppId, seminarAttendeeId, billTrackNo, paymentStatusId, paymentStatusDate, upsertedBy, isActive,planningId);
    promises.then((res) => {
       const response = res?.data?.data.length > 0 ? res?.data?.data[0] : ""
       const {plannerAppId,seminarAttendeeId} = response
       const bookingStatusId = isNotSlots === true ? 6 : 1;
       const bookingStatusDate = moment(new Date().toISOString())._i
       const  callId = plannerCall.current === undefined || plannerCall.current === "" ? selectedSlot?.plannerCalId  : plannerCall.current

       const updatedBy = commonLib.getObjFromStorage("stateObj").userId;


      updatePlannerBooking(plannerAppId,seminarAttendeeId,callId,bookingStatusId,bookingStatusDate,updatedBy);
    })
      .catch((error) => {
        konsole.log("errorerror", error)
      })
      .finally(error => {
        konsole.log("finish");
        setLoader(false);
      })
  }
  const updatePlannerBooking = (plannerAppId, seminarAttendeeId, plannerCalId, bookingStatusId, bookingStatusDate, updatedBy) => {
    setLoader(true);
    const promises = Services.updatePlannerBooking(plannerAppId, seminarAttendeeId, plannerCalId, bookingStatusId, bookingStatusDate, updatedBy);
    promises.then((res) => {
      setLoader(false);
      plannerDataRef.current = res.data.data
     
      const plannerData = res.data.data
      const attendeeUserId = attendeeObj?.attendeeUserId;
      handleWalkInCheckInUser(seminarObj?.seminarId, seminarAttendeeId, attendeeUserId, CounterPlanner, updatedBy,plannerData);
  
      
    })
      .catch((error) => {
      })
      .finally(error => {
        konsole.log("finish");
        setLoader(false);
      })
  }
  const getUserRoles = (userId, data1,plannerData) => {

    const promises = Services.GetUserRolebByID(userId);
    promises.then(res => {
    let loginId = res.data?.data[0]?.loginUserId
   
    let len = res.data.data.length;
    let data = res.data.data;
    let newData = []

    for (let i = 0; i < len; i++) {
      newData.push(data[i].roleId)
    }
    const valuesToCheck = [1, 9, 10];
    if(!valuesToCheck.some(value => newData.includes(value))) {
      adduserRole(userId,loginId)
     }
    
    if (newData.some((x) => { return x == 1 || x == 9 || x == 10 })) {
      if  (data.length > 0 && data[0]?.isActive === true && data[0]?.auth0Id != null) {

        let tempmId = templeteId.current === true ? 15 : 13
        GetEmailTemplateUsers(tempmId, true,"","","", data1,plannerData)

      }
      else {
        getActivateUser(data[0]?.loginUserId, attendeeObj?.seminarAttendeeUserId, data[0]?.signUpPlatform,stateObj?.userId,clientIpAddress,"",plannerData)
      }
    }else {
      if (newData.length === 1 && newData[0] === 2) {
        let newUser = true
        getActivateUser(data[0]?.loginUserId, attendeeObj?.seminarAttendeeUserId, data[0]?.signUpPlatform,stateObj?.userId,clientIpAddress,newUser,plannerData)

      }

    }

   


  })
    .catch(err => {
      konsole.log("err", err.response);
      // Toaster.error(err.response.data.messages[0])
    }).finally(fin => {
      // konsole.log("finish");
      // setLoader(false);
    })

  }
  const adduserRole = (userId,loginId) => {
    const promises = Services.postuserRoles(userId,loginId,seminarObj.subtenantId,1,true,stateObj?.userId);
    promises.then(res => {
       })
      .catch(err => {
        konsole.log("err", err.response);
       
      }).finally(fin => {
        // konsole.log("finish");
        // setLoader(false);
      })

  }
  const getActivateUser = (userRegstrtnId, userId, signUpPlatform, createdBy, clientIPAddress, newUser,plannerData) => {
    const promises = Services.userActivationPost(userRegstrtnId, userId, signUpPlatform, createdBy, clientIPAddress);
 
    promises.then(res => {
      let response1 = res.data.data
      let elsePart = true
      let tempmId = templeteId.current === true ? 15 : 13

      GetEmailTemplateUsers(tempmId, true, elsePart, response1, newUser,"",plannerData)

    })
      .catch(err => {
        konsole.log("errdd", err.response);
      }).finally(fin => {
        konsole.log("finish");
        setLoader(false);
      })
  }
  const GetEmailTemplateUsers = (TempalteId, isActive, elsePart, response, newUser,userData,plannerData) => {
    let subtenantId = sessionStorage.getItem('subtenantId')   
    const { plannerEmailId, bookingStatusName, plannerUserName, plannerMobileNo } = plannerData ?? {};  
    const { emailAddress, mobileNo, fName, lName } = (userData?.attendees?.length ?? 0) > 0 ? userData.attendees[0] : {};    
    const {memberName,primaryEmailId,primaryPhoneNumber} = response ?? {}   

    
    Services.GetEmailTemplateUser(TempalteId, isActive).then(res => {

      if (elsePart === true) {
        let emailMappingTablePKId = finalData.current === true ? elsePart?.seminarCoordinatorId : attendeeObj?.seminarAttendeeId.toString();
        let createdBy = stateObj?.userId;
        let allData = {
          ATTENDEENAME: memberName,
          emailId: finalData.current === true ? elsePart?.coordinatorEmailId : primaryEmailId,
          PlannerDate: commonLib.getFormattedDate(selectedSlot?.date),
          PlannerTime: selectedSlot?.time,
          UNIQUELINK: response?.activationURL,
          plannerEmailId: plannerEmailId,
          bookingStatusName: bookingStatusName,
          PlannerName: plannerUserName,
          ATTENDEEEMAIL: primaryEmailId,
          ATTENDEECELLNO: numberFormate(primaryPhoneNumber),
          COORDINATORCELLNO: numberFormate(userLoggedInDetail?.cellNo),
          plannerMobileNo: numberFormate(plannerMobileNo),
          EmailMappingTablePKId: emailMappingTablePKId,
          createdBy: createdBy,
          SUBTENANTNAME: finalData.current === true ? subtenatName : "",
          PLANNINGNAME : planningData?.planningName


        }
        if (isNotSlots == true) {
          let seminarId = seminarObj?.seminarId
          sendLatterMail(seminarId, true, memberName, response?.activationURL, primaryEmailId, primaryPhoneNumber)
          occurrenceId.callOccurrenceIdFunc(14, subtenantId, allData)
        } else {
          occurrenceId.callOccurrenceIdFunc(11, subtenantId, allData)
          occurrenceId.callOccurrenceIdFunc(12, subtenantId, allData)
        }

      }
      else {

        let allData = {
          ATTENDEENAME: fName + " " + lName,
          emailId: finalData.current === true ? elsePart?.coordinatorEmailId : emailAddress,
          PlannerDate: commonLib.getFormattedDate(selectedSlot?.date),
          PlannerTime: selectedSlot?.time,
          UNIQUELINK: newUser === true ? response?.activationURL : uniquelink,
          plannerEmailId: plannerEmailId,
          bookingStatusName: bookingStatusName,
          PlannerName: plannerUserName,
          ATTENDEEEMAIL: finalData.current === true ? emailAddress : "",
          ATTENDEECELLNO: finalData.current === true ? numberFormate(mobileNo) : "",
          COORDINATORNAME: finalData.current === true ? elsePart?.coordinatorFullName : "",
          SUBTENANTNAME: finalData.current === true ? subtenatName : ""


        }
        if (isNotSlots == true) {
          let seminarId = seminarObj?.seminarId
          sendLatterMail(seminarId, true, memberName, response?.activationURL, primaryEmailId, primaryPhoneNumber)
          occurrenceId.callOccurrenceIdFunc(14, subtenantId, allData)
        } else {
          occurrenceId.callOccurrenceIdFunc(11, subtenantId, allData)
          occurrenceId.callOccurrenceIdFunc(12, subtenantId, allData)
        }




      }
    })

      .catch(err => {
        konsole.log("err", err.response);

      }).finally(fin => {
      })

  }
    const getIpAddress = () => {
    let promise = Services.getIpAddress();
    promise.then(res => {
      setClientIpAddress(res.IPv4);
    })
  }
  let venuee = !isNotValidNullUndefinedfile(classroomObj?.selectedUserObj?.seminarVenue) ? "" : classroomObj?.selectedUserObj?.seminarVenue
      let zipCode = !isNotValidNullUndefinedfile(classroomObj?.selectedUserObj?.venueZipCode) ? "": classroomObj?.selectedUserObj?.venueZipCode
      let location = !isNotValidNullUndefinedfile(classroomObj?.selectedUserObj?.venueAddressLine1) ? "" : classroomObj?.selectedUserObj?.venueAddressLine1
      const address = `${venuee} ${location} ${zipCode}`

  const GetTextTemplateUsers1 = (TempalteId,isActive,unique)=>{

      const promises = Services.getTextTemplateFunc(TempalteId,isActive);
  
      promises.then(res => {
        let data = res.data.data[0]?.textTemplateContent
        let data2 = res.data.data[0]
        let createdBy =  stateObj?.userId; const replacedStr1 = data.
        replace('@@SEMINARNAME',seminarObj?.seminarTopic).
        replace('@@ATTENDEENAME',classroomObj?.seminar?.attendeeFullName).
        replace('@@CLASSROOMNAME',classroomObj?.selectedUserObj?.seminarTopic).
        replace('@@CLASSROOMVENUE',address).
        replace('@@CLASSROOMDATE',newDateFormate()).
        replace('@@CLASSROOM TIMINGS',newTimeFormate());
        
        let promise = Services.postSendText(data2?.textTemplateType,classroomObj?.seminar?.attendeeMobileNo
          , replacedStr1, data2?.textTemplateId, 1, "tblSeminarAttendeeList", classroomObj?.seminar?.seminarAttendeeId, createdBy)
            

        promise.then((response)=>{
            konsole.log("props?.textData?.textTemplateContent",response)
    }).catch((error)=>{
        konsole.log("sendTextAPIFuncError", error)
    }).finally(()=>{
        konsole.log("sendTextAPIFuncEnd")
    })
        
      
        konsole.log("newData",replacedStr1);
        
      })
    
      .catch(err => {
        konsole.log("err", err.response);
        
      }).finally(fin => {
      })
  
    }

  const tryAgain = () => {
    if(classroomObj1 === true ){
        history.push({
          pathname: "/classroomvenupayment",
          state: classObj
         
        });
  }
      else{
        history.push({
          pathname: "/venuePayment",
          state: attendeeObj,
        });
      
    }
   
  };
  const handelSend =(occurrenceId, isActive, subtenantId,unique)=>{
    setLoader(true
      )
      const promise = Services.postgetCommMedium(occurrenceId, isActive, subtenantId);
      promise.then((res) => {
          setLoader(false)
          let textId = res.data.data[0]?.applicableTextTempId;
          let applicableEmailTempId = res.data.data[0]?.applicableEmailTempId
          if(applicableEmailTempId !== null && applicableEmailTempId !== undefined && applicableEmailTempId !== ""){
              GetEmailTemplateUser(applicableEmailTempId,true,unique)
          }
          
      if(textId !== null && textId !== undefined && textId !== ""){
      
              GetTextTemplateUsers1(textId,true,unique)
      }
    
        
       
    
    
    }).catch((err) => {

  }).finally(error => {
    konsole.log("finish");
    setLoader(false);
  })

  }
  const SendEmailData = (emailType,emailTo,emailSubject,emailTemplateId,emailContent,emailStatusId,emailMappingTable,emailMappingTablePKId,createdBy)=>{
     
       const promises = Services.SendEmailCom(emailType,emailTo,emailSubject,emailTemplateId,emailContent,emailStatusId,emailMappingTable,emailMappingTablePKId,createdBy);
       promises.then(res => {
    
       })
       .catch(err => {
         konsole.log("err", err.response);
         
       }).finally(fin => {
       })
  }
  const data1 = (seminar11, toDate) => {
      var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      var d = new Date(seminar11);
      var dayName = days[d.getDay()];
      var d2 = new Date(toDate);
      var dayName2 = days[d2.getDay()];
      return "(" + dayName + "-" + dayName2 + ")"
  }

  const newDateFormate = ()=>{

      const toDate = classroomObj?.selectedUserObj?.remarks.slice(0, 11);
      
      const weekDay = classroomObj?.selectedUserObj?.remarks.slice(20, 40)
      
      return `${commonLib.getFormattedDate(classroomObj?.selectedUserObj?.seminarDate)} To  ${commonLib.getFormattedDate(toDate)} 
 ${data1(classroomObj?.selectedUserObj?.seminarDate, toDate)}
     
      ` 

  }
  const newTimeFormate = ()=>{
    const toTime = classroomObj?.selectedUserObj?.remarks.slice(11, 31);
    return `${commonLib.getFormattedTime(classroomObj?.selectedUserObj?.seminarTime)} To ${commonLib.getFormattedTime(toTime)}`
  }
  const GetEmailTemplateUser = (TempalteId,isActive,)=>{
    
    const promises = Services.GetEmailTemplateUser(TempalteId,isActive);

    promises.then(res => {
      let data = res.data.data[0]?.templateContent
      let data2 = res.data.data[0]
       const replacedStr1 = data.
       replace('@@SEMINARNAME',seminarObj?.seminarTopic).
      replace('@@ATTENDEENAME',classroomObj?.seminar?.attendeeFullName).
      replace('@@CLASSROOMNAME',classroomObj?.selectedUserObj?.seminarTopic).
      replace('@@CLASSROOMVENUE',address).
      replace('@@CLASSROOMDATE',newDateFormate()).
      replace('@@CLASSROOM TIMINGS',newTimeFormate());
      
     let emailType = data2?.templateType;
     let emailTo = classroomObj?.seminar?.attendeeEmailAddress;
     let emailSubject = data2?.emailSubject;
     let emailTemplateId =  data2?.templateId;
     let emailContent = replacedStr1;
     let emailStatusId =  1;
     let emailMappingTable = "tblSeminarAttendeeList";
     let emailMappingTablePKId = classroomObj?.seminar?.seminarAttendeeId;
     let createdBy =  stateObj?.userId;
     SendEmailData(emailType,emailTo,emailSubject,emailTemplateId,emailContent,emailStatusId,emailMappingTable,emailMappingTablePKId,createdBy)     
    })
      .catch(err => {
    }).finally(fin => {
    })

  }

  const classbookingNew = (bookingId,isActive,updatedBy,remarks,classroomAttendeeId)=>{
    const promise = Services. putClassBooking (bookingId,isActive,updatedBy,remarks,classroomAttendeeId)
      promise.then((res) => {        
        runFunctionsSequentially()
   
     }).catch(err => {
        
      }).finally(fin => {
      })

  }
 const handleWalkInCheckInUser = (seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy,plannerData) => {
    const promises = Services.updateSeminarAttendeeInfo(seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy);
    promises.then(res => {
       if(classroomObj1 !== true){
        if(isNotSlots !== true){
          runFunctionsSequentiallyPlanner()
        }
        
        getUserRoles(attendeeObj?.seminarAttendeeUserId, res.data?.data,plannerData)
       }
      
       })
      .catch(err => {
        setWarning(err.response.data.messages[0])
            setColor1("#FA113D")
            setShowModal(true)

        
      }).finally(fin => {
      })
  }
  const userActivateIP = (seminarAttendeeId) => {
    Services.putUserActivate1(seminarAttendeeId).then(res => {
    }).catch(err => {

    }).finally(fin => {
    })
  }

  const registerNewUser = (subtenantId1, seminarId, userRegistration,selectedUserObj) => {
    const promise = Services.registerNewUser(subtenantId1, seminarId, userRegistration)
      promise.then((res) => {
        classbookingNew(bookingId,true,stateObj?.userId,"classBooking",res.data.data?.seminarAttendeeId)
        handelSend(18, true, subtenantId)
        userActivateIP(res.data.data?.seminarAttendeeId)
        handleWalkInCheckInUser(classroomObj.seminar?.seminarid,classroomObj?.seminar?.seminarAttendeeId,classroomObj?.seminar?.seminarAttendeeUserId,11,classroomObj?.seminar?.seminarAttendeeUserId)
   
  })
      .catch(err => {
        setShowModal(true)
        setWarning(err.response.data.messages[0])
            setColor1("#FA113D")
        
      }).finally(fin => {
      })
  }
  const paymentStatus =(bookingId,isActive,updatedBy,remarks,paymentTrxnNo,paymentStatusId,bookingStatusId)=>{
     const promise = Services.putPaymentClassBookingStatus(bookingId,isActive,updatedBy,remarks,paymentTrxnNo,paymentStatusId,bookingStatusId)
      promise.then((res) => {
     })
      .catch(err => { 
      }).finally(fin => {
      })
  }
  const getLoggedInUserdata = async (email,name,method,tag) => {
    let json = {email : email,tag : tag} 
      
    const result = await leadGetApi(json);
      if(result !== null && result !== undefined && result !== ""){
        const result2 = await leadCommonApi(result,method)
      }
    if(result=='err') return;
};
const runFunctionsSequentially = async () => {
  let tag1 = [`not booked`]
  // let tags = [`booked,AO Workshop Purchase,${attendeeObj?.attendeeFullName}`]
  let tags = [`booked,AO Workshop Purchase`]
  const result1 = await getLoggedInUserdata(attendeeObj?.attendeeEmailAddress,attendeeObj?.attendeeFullName, "DELETE",tag1);
  const result2 = await getLoggedInUserdata(attendeeObj?.attendeeEmailAddress,attendeeObj?.attendeeFullName, "POST",tags);
};
const runFunctionsSequentiallyPlanner = async (data) => {
  // let tag1 = [`notbooked,${data?.attendees[0]?.fName} ${data?.attendees[0]?.lName}`]
  let tag1 = [`not booked`]
  // let tags = [`booked,Initial Consultation,${data?.attendees[0]?.fName} ${data?.attendees[0]?.lName}`]
  let tags = [`booked initial`]
  const result1 = await getLoggedInUserdata(attendeeObj?.attendeeEmailAddress,attendeeObj?.attendeeFullName, "DELETE",tag1);
  const result2 = await getLoggedInUserdata(attendeeObj?.attendeeEmailAddress,attendeeObj?.attendeeFullName, "POST",tags);
};
const sendLatterMail = (seminarId,isActive,memberName,activationURL,primaryEmailId,primaryPhoneNumber) =>{
  let subtenantId = sessionStorage.getItem('subtenantId')
  let promise = Services.getSeminarPublisher1(seminarId, isActive)
  promise.then((res) => {
    let coordinatorData = res.data.data[0].coordinators
    for (let i = 0; i < coordinatorData.length; i++) {
     
      let allData1 = {
        ATTENDEENAME: isNotValidNullUndefinedfile(memberName) && capitalizeName(memberName),
        emailId: coordinatorData[i].coordinatorEmailId,
        UNIQUELINK: activationURL,
        ATTENDEEEMAIL: primaryEmailId,
        ATTENDEECELLNO: numberFormate(primaryPhoneNumber),
        COORDINATORNAME: coordinatorData[i].coordinatorFullName,
        coordinatorEmailId: coordinatorData[i].coordinatorEmailId,
        COORDINATORCELLNO: coordinatorData[i].coordinatorMobileNo,
        SUBTENANTNAME: subtenatName


      }     
      occurrenceId.callOccurrenceIdFunc(15, subtenantId, allData1)
      }





  }).catch((error) => {
    konsole.log("errorData", error)
  }).finally(() => {
    konsole.log("end")
  }) 
  



}
  
  return (
    <LayoutComponent>
       <Notification showModal={showModal} message={warning} color={Color1} setShowModal={setShowModal}/>
       <div className="confirmationBoooking-main-div">
      {(slotIsSelected == "selectedSlot") || paymentSuccesStatus == "SUCCESS" || props.location.state?.paymentType == "Cash" || props.location.state == "Cash"  ? (
        <div >
            <div className="container-fluid bgclr confirmationBoooking-main-div">
              <div className="row">
                <div className="col-6 mt-1">
                  <h3 className=" fw-700 fs-4 CongratulationsTpic">
                    {
                      (classroomObj1 === true) ? (
                        <div>Workshop Registration: <b>Registration Confirmation</b></div>
                      ) :
                        "Appointment  Booking : Book an Appointment"
                    }

                  </h3>
                  <p className="fw-700 fs-4 CongratulationsTpic" >Topic : {seminarObj?.seminarTopic}</p>
                </div>
                {isNotSlots !== true && 
                <div className="col-6 text-end  ">
                  <p className="m me-4 ">
                    <span className="fw-bold fs-5 CongratulationsTpicdate" style={{ color: "#820024" }}>
                      Seleted Date {":"}
                    </span>{" "}
                    {classroomObj1 === true ? commonLib.getFormattedDate(classroomObj.selectedUserObj?.seminarDate) : selectedSlot?.date}
                  </p>
                  <p className="ms-2 ">
                    <span className="fw-bold fs-5 CongratulationsTpicdate" style={{ color: "#820024" }}>
                      Seleted Time Slot {":"}
                    </span>{" "}
                    {classroomObj1 === true ? commonLib.getFormattedTime(classroomObj.selectedUserObj?.seminarTime) : selectedSlot?.time}{" "}
                  </p>
                </div>}

              </div>
              <div className=" bg-white container text-center p-4">
                <h1 className="congratulationNew fw-bold fs-1  mt-3">
                  Congratulations
                </h1>
                <h3 className="fs-4 congratulationNew fw-bold ">
                  {classroomObj1 === true ?
                    "You have Successfully Registered for Workshop"
                    : isNotSlots == true ? "Your request has been sent to the event coordinator.  Our team will reach out to you at the earliest possible opportunity to set an appointment with the planner." : "You have Successfully Booked your Appointment"}
          
                </h3>
                <p className="pb-5 fs-5 congratulationNew1">
                  Details have been sent to : {attendeeObj?.attendeeEmailAddress}
                </p>
              </div>
            </div>

        </div>
    
      ) 
      : 
      (
        
            <div>
              <div className="container-fluid  bgclr confirmationBoooking-main-div">
                <div className="row">
                  <div className="col ms-3"> <h4 className="mt-4 fw-700 fs-4">
                    {
                      classroomObj1 === true ? (
                        <div>Book a Workshop</div>
                      ) :
                        "Book an Appointment"
                    }
                  </h4>
                    <p className="fw-700 fs-4 topiccc">Topic : {seminarObj?.seminarTopic}</p></div>


                </div>
                <div className=" container bg-white">
                  <div className="text-center">
                    <img className="mt-5" src="../images/Notification.png"></img>
                  </div>
                  <h1
                    className=" congratulationNew text-center  mt-2 fw-bold fs-1 pt-3 "
                    style={{ color: "#F2494B" }}
                  >
                    Your Payment is Failed
                  </h1>

                  <div className="btm text-center mt-5 pb-4">
                    <button
                      type="text"
                      className="theme-btn rounded text-center p-2"
                      onClick={tryAgain}
                    >
                      Try Again
                    </button>
                    {/* <div className="col text-danger mt-2">(or)</div>
              <div className="col text-danger mt-2">Book Later</div> */}
                  </div>


                </div>
              </div>

            </div>
    
       )}
       </div>
    </LayoutComponent>
  );
}
