import konsole from "../network/konsole";
import Services from "../network/Services";
import commonLib from "./commonLib";
import config from "../../config.json"
import {ConstantEmail,ConstantText} from './Constant' 
let count = 0

const occurrenceId = {
    callOccurrenceIdFunc: (occurrenceIdData, subtenantIdData, allData, coordinaterIndex) => {
       let subtenantIdValue = subtenantIdData;
        let promise = Services.postgetCommMedium(occurrenceIdData, true, subtenantIdValue)
        konsole.log("jhtuytuy", occurrenceIdData, true, subtenantIdValue)
        promise.then((response) => {
            konsole.log("occurrenceIdDataoccurrenceIdData", occurrenceIdData, subtenantIdData, allData, coordinaterIndex)

            konsole.log("postgetCommMediumCommom", response)
            let commChannelId = response.data.data[0]?.commChannelId
            konsole.log("errytete", commChannelId)
            let textTempId = response.data.data[0]?.applicableTextTempId
            commChannelId == 3 ? occurrenceId.callBothTextAndEmailApiFunc(response.data.data[0]?.applicableEmailTempId, response.data.data[0]?.applicableTextTempId, allData, occurrenceIdData, coordinaterIndex)

                : commChannelId == 2 ? occurrenceId.callTextApiFunc(
                    response.data.data[0]?.applicableTextTempId, allData, occurrenceIdData, coordinaterIndex)
                    : commChannelId == 1 && occurrenceId.callEmailApiFunc(response.data.data[0]?.applicableEmailTempId, allData, occurrenceIdData, coordinaterIndex);
        }).catch((error) => {
            konsole.log("postgetCommMediumerror", error.response.status, count)


            // if (error.response.status == 404) {
            //     // count = count + 1
            //     let subtenantId = config.DEFAULT_SUBTENANTID_FOR_OCCURRENCE

            //     occurrenceId.callOccurrenceIdFunc1(occurrenceIdData, subtenantId, allData, coordinaterIndex)

            // }
        }).finally(() => {
            konsole.log("postgetCommMediumEnd")
        })
    },

    callOccurrenceIdFunc1: (occurrenceIdData, subtenantIdData, allData, coordinaterIndex) => {
        konsole.log("occurrenceIdDataoccurrenceIdData", occurrenceIdData, subtenantIdData)

        let subtenantIdValue = subtenantIdData;
        let promise = Services.postgetCommMedium(occurrenceIdData, true, subtenantIdValue)
        promise.then((response) => {
            konsole.log("postgetCommMediumCommom", response)
            let commChannelId = response.data.data[0]?.commChannelId
            commChannelId == 3 ? occurrenceId.callBothTextAndEmailApiFunc(response.data.data[0]?.applicableEmailTempId, response.data.data[0]?.applicableTextTempId, allData, occurrenceIdData, coordinaterIndex) : commChannelId == 2 ? occurrenceId.callTextApiFunc(response.data.data[0]?.applicableTextTempId, allData, coordinaterIndex) : commChannelId == 1 && occurrenceId.callEmailApiFunc(response.data.data[0]?.applicableEmailTempId, allData, occurrenceIdData, coordinaterIndex);
        }).catch((error) => {
            // konsole.log("postgetCommMediumerror", error.response.status, count)


            // if(error.response.status == 404  && count == 0){
            //     count = count + 1
            //     alert("2 time")
            //     occurrenceId.callOccurrenceIdFunc(occurrenceIdData, 2, allData) 

            // }
        }).finally(() => {
            konsole.log("postgetCommMediumEnd")
        })

    },

    callBothTextAndEmailApiFunc: (emailTempalteId, textTempalteId, allData, occurrenceIdDataValue, coordinaterIndex) => {
        occurrenceId.callTextApiFunc(textTempalteId, allData, occurrenceIdDataValue, coordinaterIndex)
        occurrenceId.callEmailApiFunc(emailTempalteId, allData, occurrenceIdDataValue, coordinaterIndex)
    },

    callEmailApiFunc: (TempalteId, allData, occurrenceIdDataValue, coordinaterIndex) => {
        // konsole.log("jhfiurgh111",TempalteId, allData, occurrenceIdDataValue, coordinaterIndex)
        let promise = Services.GetEmailTemplateUser(TempalteId, true)
        promise.then((response) => {
            konsole.log("callEmailApiFuncRes", response)
            let responseData = response.data.data[0]
            konsole.log("callEmailApiFuncRes", responseData)

           konsole.log("kjguybuytuytyu",responseData, allData, occurrenceIdDataValue, coordinaterIndex)
           const lastEightDigitArray = array => array.slice(-8);

            if(occurrenceIdDataValue == 1){
               
                if(lastEightDigitArray(allData?.emailId) !== ConstantEmail){
                    // alert("1")
                    occurrenceId.sendEmailAPIFunc(responseData, allData, occurrenceIdDataValue, coordinaterIndex)

                }
               
            }
            else{
            occurrenceId.sendEmailAPIFunc(responseData, allData, occurrenceIdDataValue, coordinaterIndex)
            }
            
        }).catch((error) => {
            konsole.log("callEmailApiFuncErr", error)
        }).finally(() => {
            konsole.log("callEmailApiFuncEnd")
        })
    },

    sendEmailAPIFunc: (responseData, allData, occurrenceIdDataValue, coordinaterIndex) => {
        let emailTo;
        if (occurrenceIdDataValue == 10) {
            let coordinatorEmailid = commonLib.getObjFromStorage('seminarList3')?.coordinators[coordinaterIndex]?.coordinatorEmailId
            emailTo = coordinatorEmailid
        } else if (occurrenceIdDataValue == 12) {
            emailTo = allData?.plannerEmailId
        } else if (occurrenceIdDataValue == 15) {
            emailTo = allData?.coordinatorEmailId
        }

        else {

            emailTo = allData?.emailId
        }
        let createdBy = commonLib.getObjFromStorage('stateObj')?.userId;
        let emailMappingTable = allData?.seminarAttendeeId
        konsole.log("responseData?.templateContent", responseData?.templateContent)
        let templateData = occurrenceId.templateReplacer(responseData?.templateContent, allData, occurrenceIdDataValue)

        konsole.log("planner and email", emailTo)

        const promise = Services.SendEmailCom(responseData?.templateType, emailTo, responseData?.emailSubject, responseData?.templateId, templateData, 1, "tblSeminarAttendeeList", emailMappingTable, createdBy)
       konsole.log("utyutuy",responseData?.templateType, emailTo, responseData?.emailSubject, responseData?.templateId, templateData, 1, "tblSeminarAttendeeList", emailMappingTable, createdBy)
        promise.then((response) => {
            konsole.log("sendEmailAPIFuncRes", response)
            if (occurrenceIdDataValue != 13) {

                // alert("Email Send Successfully")
            }
        }).catch((error) => {
            konsole.log("sendEmailAPIFuncerr", error)
        }).finally(() => {
            konsole.log("sendEmailAPIFuncEnd")
        })

    },

    callTextApiFunc: (TempalteId, allData, occurrenceIdDataValue, coordinaterIndex) => {
        // alert("yes11")
        konsole.log("jhfiur343gh111", TempalteId, allData, occurrenceIdDataValue, coordinaterIndex)
        let promise = Services.getTextTemplateFunc(TempalteId, true)
        promise.then((response) => {
            konsole.log("callTextApiFuncRes", allData?.mobileno)
            let responseData = response.data.data[0]
            const lastTenDigitArray = array => array.slice(-10);
            if(occurrenceIdDataValue == 1){
                if(lastTenDigitArray(allData?.mobileno) !== ConstantText){
                    occurrenceId.sendTextAPIFunc(responseData, occurrenceIdDataValue, allData, coordinaterIndex)
                }
            }
            else{
                occurrenceId.sendTextAPIFunc(responseData, occurrenceIdDataValue, allData, coordinaterIndex)
            }
           
        }).catch((error) => {
            konsole.log("callTextApiFuncErr", error)
        }).finally(() => {
            konsole.log("callTextApiFuncEnd")
        })
    },

    sendTextAPIFunc: (responseData, occurrenceIdDataValue1, allData, coordinaterIndex) => {
        konsole.log("kjhfadees11", occurrenceIdDataValue1, allData)
        // let textTo;
        // let createdBy;
        // let textMappingTable;
        // let templateData = responseData?.textTemplateContent
        let templateData = responseData?.textTemplateContent;
        let textTo;
        let completePhnNo;
        let createdBy;
        let textMappingTable;
        let seminarName;
        let seminarDate;
        let seminarTime;


        if (occurrenceIdDataValue1 == 11 || occurrenceIdDataValue1 == 12 || occurrenceIdDataValue1 == 14 || occurrenceIdDataValue1 == 15 || occurrenceIdDataValue1 == 10 || occurrenceIdDataValue1 == 13) {

            // if(occurrenceIdDataValue == 14 || occurrenceIdDataValue == 11 || occurrenceIdDataValue == 12){
            //     textTo = allData?.ATTENDEECELLNO

            //     createdBy = allData?.createdBy
            //     textMappingTable = allData?.emailMappingTablePKId

            // }
            if (occurrenceIdDataValue1 == 14) {
                textTo = allData?.ATTENDEECELLNO
                completePhnNo = allData?.ATTENDEECELLNO
                textMappingTable = allData?.EmailMappingTablePKId
                createdBy = allData?.createdBy

            } else if (occurrenceIdDataValue1 == 11) {
                textTo = allData?.ATTENDEECELLNO
                konsole.log("EmailMappingTablePKId1", textTo)
                completePhnNo = allData?.ATTENDEECELLNO
                textMappingTable = allData?.EmailMappingTablePKId
                createdBy = allData?.createdBy
            } else if (occurrenceIdDataValue1 == 12) {
                completePhnNo = allData?.plannerMobileNo
                textTo = allData?.plannerMobileNo
                textMappingTable = allData?.EmailMappingTablePKId
                createdBy = allData?.createdBy

            }
            else if (occurrenceIdDataValue1 == 10) {
                // alert("5")
                completePhnNo = commonLib.getObjFromStorage('seminarList3')?.coordinators[coordinaterIndex]?.coordinatorMobileNo
                textTo = commonLib.getObjFromStorage('seminarList3')?.coordinators[coordinaterIndex]?.coordinatorMobileNo
                konsole.log("textTotextTo", textTo)
                textMappingTable = allData?.EmailMappingTablePKId
                createdBy = allData?.createdBy

            }
            else if (occurrenceIdDataValue1 == 13) {
                // alert("5")
                completePhnNo = allData?.ATTENDEECELLNO
                textTo = allData?.ATTENDEECELLNO
                textMappingTable = allData?.EmailMappingTablePKId
                // createdBy = allData?.seminarAttendeeId
                createdBy = commonLib.getObjFromStorage('stateObj')?.userId

            }
            else if (occurrenceIdDataValue1 == 15) {
                // alert("5")
                completePhnNo = allData?.COORDINATORCELLNO
                textTo = allData?.COORDINATORCELLNO
                textMappingTable = allData?.EmailMappingTablePKId
                createdBy = allData?.createdBy

                konsole.log("151515151515", textTo)

            }


            konsole.log("iyiuydriifhiyiuydriifh", templateData)
            templateData = occurrenceId.templateReplacer(templateData, allData, occurrenceIdDataValue1)

        } else {



            textTo = allData?.mobileno;
            createdBy = commonLib.getObjFromStorage('stateObj')?.userId;
            textMappingTable = allData?.seminarAttendeeId;

            templateData = occurrenceId.templateReplacer(templateData, allData, occurrenceIdDataValue1)

        }
        let mobilenumber = textTo?.toString().replace(/[\s()-/]/g, "")
        let promise = Services.postSendText(responseData?.textTemplateType, mobilenumber, templateData, responseData?.textTemplateId, 1, "tblSeminarAttendeeList", textMappingTable, createdBy)        
        promise.then((response) => {
            
            konsole.log("sendTextAPIFuncRes", response)
            // alert("Message Send Successfully")
        }).catch((error) => {
            konsole.log("sendTextAPIFuncError", error, occurrenceIdDataValue1)
        }).finally(() => {
            konsole.log("sendTextAPIFuncEnd")
        })


    },


    templateReplacer: (templateData, allData, occurrenceIdDataValue) => {
        let updatedTemplateData = templateData;
        let ATTENDEENAME;
        if (occurrenceIdDataValue == 13) {
            ATTENDEENAME = allData?.ATTENDEENAME

            konsole.log("templateDatatemplateData11111", templateData)
            updatedTemplateData = updatedTemplateData?.replace('@@ATTENDEENAME', allData?.ATTENDEENAME)
            konsole.log("templateDatatemplateData11111allData?.ATTENDEENAME", updatedTemplateData)
        } else if (occurrenceIdDataValue == 11 || occurrenceIdDataValue == 12 || occurrenceIdDataValue == 14 || occurrenceIdDataValue == 15) {
            konsole.log("AttendeeNameAttendeeName", updatedTemplateData)
            konsole.log("AttendeeNameAttendeeName1", allData)
            updatedTemplateData = updatedTemplateData?.replace('@@ATTENDEENAME,', allData?.ATTENDEENAME)
            updatedTemplateData = updatedTemplateData?.replace('@@ATTENDEENAME', allData?.ATTENDEENAME)
            updatedTemplateData = updatedTemplateData?.replace('@@ATENDEENAME', allData?.ATTENDEENAME)
            updatedTemplateData = updatedTemplateData?.replace('@@atendeename', allData?.ATTENDEENAME)
            updatedTemplateData = updatedTemplateData?.replace('@@attendeename', allData?.ATTENDEENAME)
            updatedTemplateData = updatedTemplateData?.replace(' @@ATTENDEENAME ', allData?.ATTENDEENAME)
            updatedTemplateData = updatedTemplateData?.replace('@@AttendeeName', allData?.ATTENDEENAME)
            updatedTemplateData = updatedTemplateData?.replace('@@AttendeeName', allData?.ATTENDEENAME)
            updatedTemplateData = updatedTemplateData?.replace('@@ATTENDEEEMAIL', allData?.emailId)
            updatedTemplateData = updatedTemplateData?.replace('@@AttendeeEmail', allData?.emailId)
            updatedTemplateData = updatedTemplateData?.replace('@@attendeeemail', allData?.emailId)
            updatedTemplateData = updatedTemplateData?.replace('@@attendeeemail', allData?.emailId)
            updatedTemplateData = updatedTemplateData?.replace('@@PLANNERDATE', allData?.PlannerDate)
            updatedTemplateData = updatedTemplateData?.replace('@@plannerdate', allData?.PlannerDate)
            updatedTemplateData = updatedTemplateData?.replace('@@PlannerDate', allData?.PlannerDate)
            updatedTemplateData = updatedTemplateData?.replace('@@PLANNERTIME', allData?.PlannerTime)
            updatedTemplateData = updatedTemplateData?.replace('@@PlannerTime', allData?.PlannerTime)
            updatedTemplateData = updatedTemplateData?.replace('@@plannertime', allData?.PlannerTime)
            updatedTemplateData = updatedTemplateData?.replace(/@@UNIQUELINK/g, allData?.UNIQUELINK)
            updatedTemplateData = updatedTemplateData?.replace('@@INTAKELINK', allData?.UNIQUELINK)
            updatedTemplateData = updatedTemplateData?.replace(/@@uniquelink/g, allData?.UNIQUELINK)
            updatedTemplateData = updatedTemplateData?.replace('@@intakelink', allData?.UNIQUELINK)
            updatedTemplateData = updatedTemplateData?.replace(/@@UNIQUELINK/g, allData?.UNIQUELINK)
            updatedTemplateData = updatedTemplateData?.replace(' @@INTAKELINK', allData?.UNIQUELINK)
            updatedTemplateData = updatedTemplateData?.replace('@@BOOKINGSTATUS', allData?.bookingStatusName +" "+ allData?.PLANNINGNAME)
            updatedTemplateData = updatedTemplateData?.replace('@@BookingStatus', allData?.bookingStatusName +" "+ allData?.PLANNINGNAME)
            updatedTemplateData = updatedTemplateData?.replace('@@bookingstatus', allData?.bookingStatusName +" "+ allData?.PLANNINGNAME)
            updatedTemplateData = updatedTemplateData?.replace('@@PLANNERNAME', allData?.PlannerName)
            updatedTemplateData = updatedTemplateData?.replace('@@PlannerName', allData?.PlannerName)
            updatedTemplateData = updatedTemplateData?.replace('@@plannername', allData?.PlannerName)
            updatedTemplateData = updatedTemplateData?.replace('@@ATTENDEEEMAIL', allData?.ATTENDEEEMAIL)
            updatedTemplateData = updatedTemplateData?.replace('@@ATTENDEECELLNO', allData?.ATTENDEECELLNO)
            updatedTemplateData = updatedTemplateData?.replace('@@attendeecellno', allData?.ATTENDEECELLNO)
            updatedTemplateData = updatedTemplateData?.replace('@@COORDINATORNAME', allData?.COORDINATORNAME)
            updatedTemplateData = updatedTemplateData?.replace('@@coordinatorname', allData?.COORDINATORNAME)
            updatedTemplateData = updatedTemplateData?.replace('@@SUBTENANTNAME', allData?.SUBTENANTNAME)
            updatedTemplateData = updatedTemplateData?.replace('@@subtenantname', allData?.SUBTENANTNAME)
            updatedTemplateData = updatedTemplateData?.replace('@@PlanningName', allData?.PLANNINGNAME)


        } else {


            ATTENDEENAME = commonLib.getObjFromStorage('GetResponse')?.userRegistration
            ATTENDEENAME = (allData?.firstName !== null && allData?.firstName !== undefined && allData?.firstName !== "") ? allData?.firstName : ATTENDEENAME?.firstName
        }

        let seminarName = occurrenceIdDataValue == 13 ? allData?.EVENTNAME :  commonLib.getObjFromStorage('seminarObj')?.seminarTopic
        let SUBTENANTNAME = sessionStorage.getItem('subtenatName')
        let EVENTDATE = commonLib.getObjFromStorage('seminarObj')?.seminarDate
        let EVENTTIME = commonLib.getObjFromStorage('seminarObj')?.seminarTime
        let EVENTVANUE = commonLib.getObjFromStorage('seminarList2')?.seminarVenue
        let EVENTADDRESS = commonLib.getObjFromStorage('seminarList2')?.venueAddressLine1
        let MAPLINK = commonLib.getObjFromStorage('seminarList2')?.mapsLink

        let SPEAKERNAME = commonLib.getObjFromStorage('seminarList2')?.speakers
        SPEAKERNAME = `${SPEAKERNAME[0]?.fName} ${SPEAKERNAME[0]?.lName}.`

        konsole.log("allDataallData", ATTENDEENAME)
        konsole.log("allDataallData", SPEAKERNAME)
        // let seminarDate = commonLib.getObjFromStorage('seminarObj')?.seminarDate
        // let seminarTime = commonLib.getObjFromStorage('seminarObj')?.seminarTime




        updatedTemplateData = updatedTemplateData?.replace('@@SUBTENANTNAME', SUBTENANTNAME)
        updatedTemplateData = updatedTemplateData?.replace('@@subtenantname', SUBTENANTNAME)
        updatedTemplateData = updatedTemplateData?.replace('@@SEMINARTOPIC', seminarName)
        updatedTemplateData = updatedTemplateData?.replace('@@SeminarTopic', seminarName)
        updatedTemplateData = updatedTemplateData?.replace('@@seminartopic', seminarName)
        updatedTemplateData = updatedTemplateData?.replace('@@EVENTNAME', seminarName)
        updatedTemplateData = updatedTemplateData?.replace('@@eventname', seminarName)
        updatedTemplateData = updatedTemplateData?.replace('@@SEMINARNAME.', seminarName)
        updatedTemplateData = updatedTemplateData?.replace('@@SEMINARNAME', seminarName)
        updatedTemplateData = updatedTemplateData?.replace('@@SeminarName', seminarName)
        updatedTemplateData = updatedTemplateData?.replace('@@seminarname', seminarName)
        updatedTemplateData = updatedTemplateData?.replace('@@SEMINARATTENDEE', ATTENDEENAME)
        updatedTemplateData = updatedTemplateData?.replace('@@Seminarattendee', ATTENDEENAME)
        updatedTemplateData = updatedTemplateData?.replace('@@seminarattendee', ATTENDEENAME)
        updatedTemplateData = updatedTemplateData?.replace('@@ATTENDEENAME', ATTENDEENAME)
        updatedTemplateData = updatedTemplateData?.replace('@@attendeename', ATTENDEENAME)
        updatedTemplateData = updatedTemplateData?.replace('@@ATTENDEENAME,', ATTENDEENAME)
        updatedTemplateData = updatedTemplateData?.replace('@@SPEAKERNAME.', SPEAKERNAME)
        updatedTemplateData = updatedTemplateData?.replace('@@speakername.', SPEAKERNAME)
        updatedTemplateData = updatedTemplateData?.replace('@@EVENTVANUE', (EVENTVANUE !== null && EVENTVANUE !== undefined && EVENTVANUE !== "" ? EVENTVANUE : ""))
        updatedTemplateData = updatedTemplateData?.replace('@@EVENTVENUE', (EVENTVANUE !== null && EVENTVANUE !== undefined && EVENTVANUE !== "" ? EVENTVANUE : ""))
        updatedTemplateData = updatedTemplateData?.replace('@@eventvenue', (EVENTVANUE !== null && EVENTVANUE !== undefined && EVENTVANUE !== "" ? EVENTVANUE : ""))
        updatedTemplateData = updatedTemplateData?.replace('@@eventvanue', (EVENTVANUE !== null && EVENTVANUE !== undefined && EVENTVANUE !== "" ? EVENTVANUE : ""))
        updatedTemplateData = updatedTemplateData?.replace('@@EVENTADDRESS', (EVENTADDRESS !== null && EVENTADDRESS !== undefined && EVENTADDRESS !== "" ? EVENTADDRESS : ""))
        updatedTemplateData = updatedTemplateData?.replace('@@eventaddress', (EVENTADDRESS !== null && EVENTADDRESS !== undefined && EVENTADDRESS !== "" ? EVENTADDRESS : ""))
        updatedTemplateData = updatedTemplateData?.replace('@@EVENTTIME', `${commonLib.getFormattedDate(EVENTDATE)}, ${commonLib.getFormattedTime(EVENTTIME)}`)
        updatedTemplateData = updatedTemplateData?.replace('@@eventtime', `${commonLib.getFormattedDate(EVENTDATE)}, ${commonLib.getFormattedTime(EVENTTIME)}`)
        updatedTemplateData = updatedTemplateData?.replace('@@SEMINARTIME', commonLib.getFormattedTime(EVENTTIME))
        updatedTemplateData = updatedTemplateData?.replace('@@SeminarTime', commonLib.getFormattedTime(EVENTTIME))
        updatedTemplateData = updatedTemplateData?.replace('@@seminartime', commonLib.getFormattedTime(EVENTTIME))
        updatedTemplateData = updatedTemplateData?.replace('@@SEMINARDATE', commonLib.getFormattedDate(EVENTDATE))
        updatedTemplateData = updatedTemplateData?.replace('@@SeminarDate', commonLib.getFormattedDate(EVENTDATE))
        updatedTemplateData = updatedTemplateData?.replace('@@seminardate', commonLib.getFormattedDate(EVENTDATE))
        updatedTemplateData = updatedTemplateData?.replace('@@MAPLINK', MAPLINK)
        updatedTemplateData = updatedTemplateData?.replace('@@maplink', MAPLINK)
        updatedTemplateData = updatedTemplateData?.replace("@@SEATNOS", allData?.seatNo)
        updatedTemplateData = updatedTemplateData?.replace("@@seatnos", allData?.seatNo)
        updatedTemplateData = updatedTemplateData?.replace("@@BOOKTYPE", allData?.BOOKTYPE)
        updatedTemplateData = updatedTemplateData?.replace("@@booktype", allData?.BOOKTYPE)
        updatedTemplateData = updatedTemplateData?.replace("@@ATTENDEEEMAIL", allData?.ATTENDEEEMAIL)
        updatedTemplateData = updatedTemplateData?.replace("@@attendeeemail", allData?.ATTENDEEEMAIL)
        updatedTemplateData = updatedTemplateData?.replace("@@ATTENDEECELLNO", allData?.ATTENDEECELLNO)
        updatedTemplateData = updatedTemplateData?.replace("@@attendeecellno", allData?.ATTENDEECELLNO)
        updatedTemplateData = updatedTemplateData?.replace("@@PAYMENTMODE", allData?.PAYMENTMODE)
        updatedTemplateData = updatedTemplateData?.replace("@@paymentmode", allData?.PAYMENTMODE)
        updatedTemplateData = updatedTemplateData?.replace("@@PAIDAMOUNT", allData?.PAIDAMOUNT)
        updatedTemplateData = updatedTemplateData?.replace("@@paidamount", allData?.PAIDAMOUNT)
        updatedTemplateData = updatedTemplateData?.replace("@@PAYMENTDATE", allData?.PAYMENTDATE)
        updatedTemplateData = updatedTemplateData?.replace("@@paymentdate", allData?.PAYMENTDATE)
        updatedTemplateData = updatedTemplateData?.replace("@@COORDINATORNAME", allData?.COORDINATORNAME)
        updatedTemplateData = updatedTemplateData?.replace("@@coordinatorname", allData?.COORDINATORNAME)
        updatedTemplateData = updatedTemplateData?.replace("@@SUBTENANTNAME", allData?.SUBTENANTNAME)
        updatedTemplateData = updatedTemplateData?.replace("@@subtenantname", allData?.SUBTENANTNAME)
        updatedTemplateData = updatedTemplateData?.replace("@@COORDINATOREMAIL", allData?.COORDINATOREMAIL)
        updatedTemplateData = updatedTemplateData?.replace("@@coordinatoremail", allData?.COORDINATOREMAIL)
        updatedTemplateData = updatedTemplateData?.replace("@@COORDINATORCELLNO", allData?.COORDINATORCELLNO)
        updatedTemplateData = updatedTemplateData?.replace("@@coordinatorcellno", allData?.COORDINATORCELLNO)
        updatedTemplateData = updatedTemplateData?.replace("@@DESCRIPTION", allData?.Description)

        // // let updatedTemplateData;
        // if(updatedTemplateData.includes("@@")){

        //     let dataSplit = updatedTemplateData.split(" ")
        //     for(let i =0; i< dataSplit.length; i++){
        //         let dataSplitValue = dataSplit[i]
        //         let dataSplitSingle = dataSplit[i].split("").splice(0,2)
        //         let dataSplitSingle1 = dataSplitSingle.join("")

        //         if(dataSplitSingle1 == "@@"){
        // konsole.log("dataSplitValue",dataSplitValue);

        // switch(dataSplitValue) {
        //   case "@@SeminarName":
        //     updatedTemplateData = updatedTemplateData.replace("@@SeminarName", seminarName)
        //     break;
        //   case "@@SEMINARNAME":
        //     updatedTemplateData = updatedTemplateData.replace("@@SEMINARNAME", seminarName)
        //     break;
        //   case "@@SEMINARNAME.":
        //     updatedTemplateData = updatedTemplateData.replace("@@SEMINARNAME.", `${seminarName}.`)
        //     break;
        //   case "@@SEATNOS":
        //     updatedTemplateData = updatedTemplateData.replace("@@SEATNOS", allData?.seatNo)

        //     break;
        //   case "@@ATTENDEENAME,":
        //     updatedTemplateData = updatedTemplateData.replace("@@ATTENDEENAME,", `${allData?.ATTENDEENAME},`)

        //     break;
        //   case "@@ATTENDEENAME":
        //     updatedTemplateData = updatedTemplateData.replace("@@ATTENDEENAME", allData?.ATTENDEENAME)

        //     break;
        //   case "@@BOOKTYPE":
        //     updatedTemplateData = updatedTemplateData.replace("@@BOOKTYPE", allData?.BOOKTYPE)

        //     break;
        //   case "@@EVENTNAME":
        //     updatedTemplateData = updatedTemplateData.replace("@@EVENTNAME", allData?.EVENTNAME)

        //     break;
        //   case "@@ATTENDEEEMAIL":
        //     updatedTemplateData = updatedTemplateData.replace("@@ATTENDEEEMAIL", allData?.ATTENDEEEMAIL)

        //     break;
        //     case "@@ATTENDEECELLNO":
        // updatedTemplateData = updatedTemplateData.replace("@@ATTENDEECELLNO", allData?.ATTENDEECELLNO)

        //     break;
        //     case "@@PAYMENTMODE":
        // updatedTemplateData = updatedTemplateData.replace("@@PAYMENTMODE", allData?.PAYMENTMODE)

        //     break;
        //     case "@@PAIDAMOUNT":
        // updatedTemplateData = updatedTemplateData.replace("@@PAIDAMOUNT", allData?.PAIDAMOUNT)

        //     break;
        //     case "@@PAYMENTDATE":
        //     updatedTemplateData = updatedTemplateData.replace("@@PAYMENTDATE", allData?.PAYMENTDATE)

        //     break;
        //     case "@@COORDINATORNAME":
        //     updatedTemplateData = updatedTemplateData.replace("@@COORDINATORNAME", allData?.COORDINATORNAME)

        //     break;
        //     case "@@COORDINATOREMAIL":
        //     updatedTemplateData = updatedTemplateData.replace("@@COORDINATOREMAIL", allData?.COORDINATOREMAIL)

        //     break;
        //     case "@@COORDINATORCELLNO":
        // updatedTemplateData = updatedTemplateData.replace("@@COORDINATORCELLNO", allData?.COORDINATORCELLNO)

        //     break;
        //     case "@@SUBTENANTNAME":
        //     updatedTemplateData = updatedTemplateData.replace("@@SUBTENANTNAME", allData?.SUBTENANTNAME)

        //     break;
        //   default:

        // }

        // }

        // }

        konsole.log("updatedTemplateData", updatedTemplateData)


        return updatedTemplateData


        // }

    }





}


export default occurrenceId;
