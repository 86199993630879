import React, { useState,useEffect } from 'react'
import LayoutComponent from '../../LayoutComponent';
import '../classroomPayment/classpayment.css'
import './classpayment.css';
import Payment from '../../VenuePayment/Payment';
import commonLib from "../../control/commonLib";
import Notification from '../../Notification';
import Services from '../../network/Services';
import konsole from '../../network/konsole';


const Classroomvenupayment = (props) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [showPayment, setshowPayment] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('')
  const seminarObj = commonLib.getObjFromStorage("seminarObj");
  const selectedUser  = (props.location.state !== undefined) ? props.location.state : {};
  const [warning, setWarning] = useState()
    const [Color1, setColor1] = useState("")
  
    const [showModal , setShowModal] = useState(false) 
    const [popsStatus, setPopsStatus] = useState()
    useEffect(()=>{
      if(popsStatus == false){
        setshowPayment(false)
      }else{
        setPopsStatus(true)
  
      }
  
    },[popsStatus])
  


  konsole.log("ssfdd",selectedUser)
  
  const handlePaymentMethod = (selectedPaymentMethod, method) => {
    setSelectedPaymentMethod(selectedPaymentMethod);
    setPaymentMethod(method)

  }
 
  const handlepayment = () => {
  
    if (paymentMethod == '' || paymentMethod == null) {
   
      setWarning("Please select  payment method")
            setColor1("#ffcc00")
            setShowModal(true)
      return;
    } else {
      setshowPayment(true)
      setPopsStatus(true)
      
    }
  }
  let formatPhoneNumber = (str) => {
    let cleaned = ("" + str).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return null;
  };

  return (
    <div>
      <LayoutComponent>
      <Notification showModal={showModal} message={warning} color={Color1} setShowModal={setShowModal}/>

      <div className=' container-fluid bgclr class-room-venu-payment' >
        <div className='row'>
          <div className='col-sm-6 '>
          <h4>Workshop Registration: Payment<b> </b></h4>
           
            <p className='fs-5'>Topic :
             {seminarObj?.seminarTopic}
             </p>
          </div>
        </div>
        <div className='container-fluid d-flex justify-content-center align-items-center ' >
          <div className='col-sm-12 bg-white d-flex flex-column flex-md-row p-0 p-md-2' >
            <div className='col-xs-4 border-end'>
              <div className='ms-4 m-ms-md-0'>
                <dl className="user-Details-Div">
                  <h4><b>User Details</b></h4>
                  < div className="dt fs-5">First name :{
                            selectedUser?.selectedUser?.attendeeFullName 
                } <span className="text-secondary">
               
                  </span> </div>
                  <dd id="lblName"></dd>
                  <div className="dt fs-5">Email :{
                   selectedUser.selectedUser.attendeeEmailAddress 
                    } <span className='text-secondary'>
                 
                  </span></div>
                  <dd id="lblId"></dd>
                  <div className="dt fs-5">Cell Number : 
                        {
                        selectedUser.selectedUser?.attendeeMobileNo.toString().slice(0,-10) } 
                          { " "}
                           {formatPhoneNumber(selectedUser.selectedUser?.attendeeMobileNo.toString().slice(-10))
                           }
                  
                  <span className='text-secondary'>
                
                  </span></div>
                  <dd id="lblId"></dd>
             
                  <dd id="lblAmenities"></dd>
                </dl>
                <dl className="user-Details-Div">
                  <h5><b>Workshop Details</b></h5>
                  < div className="dt fs-5">Workshop name :{selectedUser?.selectedUserObj?.seminarTopic} <span className="text-secondary">
             
                  </span> </div>
                  {/* < div className="dt fs-5">Description :{selectedUser?.selectedUserObj?.description} <span className="text-secondary">
             
                  </span> </div> */}
                  <dd id="lblName"></dd>
                  <div className="dt fs-5">Selected Date:
            
                  {commonLib.getFormattedDate(selectedUser?.selectedUserObj?.seminarDate)}
                  <span className='text-secondary'>
              
                  </span></div>
                  <dd id="lblId"></dd>
                  <div className="dt fs-5">Selected Time : 
           {commonLib.getFormattedTime(selectedUser?.selectedUserObj?.seminarTime)}
                  <span className='text-secondary'>
               
                  </span></div>
                  <dd id="lblId"></dd>
                  < div className="dt fs-6" >Fee:$ {selectedUser?.selectedUserObj?.seminarFee}
                     
                   <span className='text-secondary'>
                    </span></div>
                  <dd id="lblAmenities"></dd>
                </dl>
              </div>
            </div>
            <div className='col-12 col-md-6 ps-4' >
              <h4> Choose a Payment Method</h4>
              <div className='row'>
                <div className="col-10 col-md-4 p-2 cursor-pointer" onClick={() => handlePaymentMethod(1, 'Card')}>
                  <div class="card">
                    <div class={`${(selectedPaymentMethod === 1) ? "seletectedIndexBackground" : ''} card-body text-center rounded `}>
                      <h5 class="card-title "><img src={`${(selectedPaymentMethod === 1) ? '/icons/creditActiveIcon.svg' : '/icons/creditcardPayment.svg'}`} /></h5>
                      <p className='fw-bold'> Credit/Debit Card</p>
                    </div>
                  </div>
                </div>
                <div className="col-10 col-md-4 p-2 cursor-pointer" onClick={() => handlePaymentMethod(3, "Cash")}>
                  <div class="card ">
                    <div class={`${(selectedPaymentMethod === 3) ? "seletectedIndexBackground" : ''} card-body text-center rounded `}>
                      <div class="">
                        <h5 className='card-title'><img src={`${(selectedPaymentMethod === 3) ? '/icons/cashActiveIcon.svg' : '/icons/cashPayment.svg'}`} /></h5>
                        <span className='fw-bold'>Cash</span>
                        <p className='vanu-counter'> </p>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className='text-center col-6 m-auto mt-5 pb-4'>
                <button type='text' className='px-4 py-1 theme-btn rounded text-center ' onClick={handlepayment}> Payment</button>

              </div>
            </div>
          </div>
        </div>
      </div >
      <>



        {
          showPayment ? <Payment selectedUser1={selectedUser}paymentMethod={paymentMethod}setPopsStatus={setPopsStatus} /> : ''
        }

      </>



    </LayoutComponent>
    </div>
  )
}

export default Classroomvenupayment




