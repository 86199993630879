import axios from 'axios';
import config from '../../config.json'
import konsole from "./konsole";
import AoUrl from './url';
import { mapKey, mapApi, LeadStackerToken, LeadStackerToken1 } from '../control/Constant'
import { BASE_URL, LeadStackerBASE_URL } from './url';
import commonLib from '../control/commonLib';


// axios.defaults.timeout = 15000;
axios.defaults.baseURL = BASE_URL;
// axios.defaults.headers.post['Content-Type'] = 'application/json;text/html;charset=utf-8';
// axios.defaults.headers.post['Accept'] = 'application/json, text/plain, */*';
// axios.defaults.headers.post['origin'] = 


const invoke = (url, method, body, cancel) => {

    if (cancel) {
        cancel();
    }

    try {
        let token = sessionStorage.getItem("AuthToken");

        konsole.log("token ", `Bearer ${token}`);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        konsole.log('URL: ' + url);
        konsole.log('method:' + method);
        konsole.log(((method === "POST") ? "body" : 'params') + JSON.stringify(body));

        return axios({
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            }),
            method: method,
            url: url,
            params: (method === "GET") ? body : null,
            data: (method === "POST" || method === "PUT") ? body : null
        });
    } catch (error) {
        if (axios.isCancel(error)) {
            konsole.error('Request canceled', error.message);
        } else {
            konsole.error('Something went wrong: ', error.message)
        }
    }
};
const invokes = (url, method, body, cancel) => {

    if (cancel) {
        cancel();
    }

    try {
        let subtenantId = sessionStorage.getItem("subtenantId");
        let token = subtenantId == 3 ? LeadStackerToken1 : subtenantId == 742 || subtenantId == 747 ? LeadStackerToken : ""

        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        return axios({
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            }),
            method: method,
            url: url,
            params: (method === "GET") ? body : null,
            data: (method === "POST" || method === "DELETE" || method === "PUT") ? body : null
        });
    } catch (error) {
        if (axios.isCancel(error)) {
            konsole.error('Request canceled', error.message);
        } else {
            konsole.error('Something went wrong: ', error.message)
        }
    }
};

const Services = {
    getIpAddress: async () => {
        let data = await fetch(AoUrl.getIpAddressUrlPath);
        return await data.json();
    },
    getCountryCodeList: async () => {
        let cancel;
        let body = {};
        let url = AoUrl.getCountryCodeListPath
        return invoke(url, "GET", body, cancel);
    },
    getContactLead: async (email) => {
        let cancel;
        let url = `${LeadStackerBASE_URL}/${AoUrl.getLeadstackerContacts}?email=${email}`
        let body = {};
        return invokes(url, "GET", body, cancel);
    },
    postContactLead: async (json) => {
        let cancel;
        let url = LeadStackerBASE_URL + "/" + AoUrl.postLeadstackerContacts + json?.id + "/tags/"
        let body = {};
        body['tags'] = json.tags;

        return invokes(url, "POST", body, cancel);
    },
    putContactLeadRegist: async (json, tag) => {
        let cancel;
        let url = LeadStackerBASE_URL + "/" + AoUrl.postLeadstackerContacts + json?.id
        let body = {};
        body['email'] = tag;

        return invokes(url, "PUT", body, cancel);
    },
    postContactLeadRegist: async (json, tag) => {
        // console.log("samkkkkkk",json)
        let cancel;
        let url = LeadStackerBASE_URL + "/" + AoUrl.postLeadstackerContacts
        let body = {};
        body['email'] = json.emailAddress;
        body['phone'] = json.phoneNumber;
        body['firstName'] = json.firstName;
        body['lastName'] = json.lastName;
        body['tags'] = tag;

        return invokes(url, "POST", body, cancel);
    },
    postLogUserout: async () => {
        const stateObj = commonLib.getObjFromStorage('stateObj');
        const userId = stateObj.userId;
        const appState = stateObj.appState;
        const loggenInId = stateObj.loggenInId;
        const roleId = stateObj.roleId;

        let cancel;
        let url = AoUrl.postLogUserout + `${userId}/${appState}/${loggenInId}/${roleId}`;
        let body = {};
        return invoke(url, "GET", body, cancel);
    },
    deleteContactLead: async (json) => {

        let cancel;
        let url = LeadStackerBASE_URL + "/" + AoUrl.postLeadstackerContacts + json?.id + "/tags/"
        let body = {};
        body['tags'] = json.deleteTagss;
        // console.log("json.deleteTagss",body)
        return invokes(url, "DELETE", body, cancel);
    },
    getSubtenantDetail: async (subtenantId, subtenantLogoUrl, isActive) => {
        let cancel;
        let url = AoUrl.getSubtenantDetailPath
        let body = {};
        body['subtenantId'] = subtenantId;
        body['subtenantLogoUrl'] = subtenantLogoUrl;
        body['isActive'] = isActive;

        return invoke(url, "POST", body, cancel);
    },
    getSeminarAttendee: async () => {
        let cancel;
        let url = AoUrl.getSeminarAttendeePath
        let body = {};
        // body[''] = subtenantId;

        return invoke(url, "GET", body, cancel);
    },
    getPlannerBookingDetails: async (isActive) => {
        let cancel;
        let url = AoUrl.getPlannerBookingDetails
        let body = {};

        body['isActive'] = isActive;

        return invoke(url, "POST", body, cancel);
    },

    getSeminarUserList: async (userType, subtenantId, seminarId, seminarAttendeeId) => {
        let cancel;
        let url = AoUrl.getSeminarUserListPath
        let body = {};

        body['subtenantId'] = subtenantId || 2;
        body['seminarId'] = seminarId || null;
        body['seminarAttendeeId'] = seminarAttendeeId || null;
        body['userType'] = userType;

        return invoke(url, "POST", body, cancel);
    },
    upsertSeminarAttendee: async (seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, userGuestsNos, leadFrom, upsertedBy, isActive) => {
        let cancel;
        let url = AoUrl.upsertSeminarAttendeePath
        let body = {};
        let attendees = {};
        attendees['seminarAttendeeId'] = parseInt(seminarAttendeeId);
        attendees['attendeeUserId'] = attendeeUserId;
        attendees['semUserStatusId'] = parseInt(semUserStatusId);
        attendees['userGuestsNos'] = parseInt(userGuestsNos);
        attendees['upsertedBy'] = upsertedBy;
        attendees['isActive'] = isActive;
        attendees['leadFrom'] = leadFrom;
        body['seminarId'] = seminarId;
        body['attendees'] = [attendees] || null;

        return invoke(url, "POST", body, cancel);
    },
    registerNewUser: async (subtenantId, seminarId, userRegistration) => {
        let cancel;
        let url = AoUrl.registerWalkInUserPath
        let body = {};
        let attendees = {};
        body['subtenantId'] = subtenantId;
        body['seminarId'] = seminarId;
        body['userRegistration'] = userRegistration;
        //konsole.log("bofyyy",body)
        return invoke(url, "POST", body, cancel);
    },
    updateSeminarAttendeeInfo: async (seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy) => {
        let cancel;
        let url = AoUrl.updateSeminarAttendeeInfoPath
        let body = {};
        body['seminarAttendeeId'] = parseInt(seminarAttendeeId);
        body['attendeeUserId'] = attendeeUserId;
        body['semUserStatusId'] = parseInt(semUserStatusId);
        body['upsertedBy'] = upsertedBy;
        body['seminarId'] = seminarId;
        // konsole.log("body11",JSON.stringify(body))
        return invoke(url, "PUT", body, cancel);
    },
    updateSeminarSheetStaus: async (seminarId, seminarAttendeeId, SeatStatus) => {
        let cancel;
        let url = AoUrl.putForSheetAlot + `${seminarId}/${seminarAttendeeId}/${SeatStatus}/`;
        let body = {}
        return invoke(url, "PUT", cancel);
    },
    GetEmailTemplateUser: async (TempalteId, isActive) => {
        let cancel;
        let url = AoUrl.GetEmailTemplate + `${TempalteId}&IsActive=${isActive}`;

        return invoke(url, "GET", cancel);
    },
    GetUserRolebByID: async (userId) => {
        let cancel;
        let url = AoUrl.GetUserRole + `${userId}/`;
        let body = {}

        // body['subtenantId'] = subtenantId;

        // konsole.log("body",JSON.stringify(body))



        return invoke(url, "GET", cancel);
    },

    userActivationPost: async (userRegstrtnId, userId, signUpPlatform, createdBy, clientIPAddress) => {
        let cancel;
        let url = AoUrl.userActivation
        let body = {};
        body['userRegstrtnId'] = userRegstrtnId;
        body['userId'] = userId;
        body['signUpPlatform'] = signUpPlatform;
        body['createdBy'] = createdBy;
        body['clientIPAddress'] = clientIPAddress;


        return invoke(url, "POST", body, cancel);


    },
    getSeminarPublisherDetail: async (subtenantId, isActive, seminarId, coordinatorUserId) => {
        let cancel;
        let url = AoUrl.getSeminarPublishePath
        let body = {};
        body['subtenantId'] = subtenantId;
        if (seminarId !== null) {
            body['seminarId'] = seminarId;
        }
        body['coordinatorUserId'] = coordinatorUserId;
        body['isActive'] = isActive;

        return invoke(url, "POST", body, cancel);


    },
    getClassroomPublisherDetail: async (subtenantId, isActive, seminarType) => {
        let cancel;
        let url = AoUrl.getSeminarPublishePath
        let body = {};
        body['subtenantId'] = subtenantId;
        // if(seminarId !== null){
        //     body['seminarId'] = seminarId;
        // }
        // body['coordinatorUserId'] = coordinatorUserId;
        body['isActive'] = isActive;
        body['seminarType'] = seminarType;

        return invoke(url, "POST", body, cancel);
    },
    getReferenceMode: async () => {
        let cancel;
        let url = AoUrl.getReferenceModePath
        let body = {};
        return invoke(url, "GET", body, cancel);
    },
    getPlannerPlan: async () => {
        let cancel;
        let url = AoUrl.getPlannerPlans
        let body = {};
        return invoke(url, "GET", body, cancel);
    },
    getPlannerCalender: async (isActive, slotStatusId, slotStatus, subtenantId) => {
        let cancel;
        let url = AoUrl.getPlannerCalenderPath
        let body = {};
        body['isActive'] = isActive;
        body['slotStatusId'] = slotStatusId;
        body['slotStatus'] = slotStatus;
        body['subtenantId'] = subtenantId;

        return invoke(url, "POST", body, cancel);
    },
    upsertPlannerBooking: async (plannerAppId, seminarAttendeeId, billTrackNo, paymentStatusId, paymentStatusDate, upsertedBy, isActive, planningId) => {
        let cancel;
        let url = AoUrl.upsertPlannerBookingPath
        let body = [];
        let bookingSlot = {};
        bookingSlot['plannerAppId'] = parseInt(plannerAppId);
        bookingSlot['seminarAttendeeId'] = seminarAttendeeId;
        bookingSlot['upsertedBy'] = upsertedBy;
        bookingSlot['isActive'] = isActive;
        bookingSlot['billTrackNo'] = billTrackNo;
        bookingSlot['paymentStatusId'] = paymentStatusId;
        bookingSlot['paymentStatusDate'] = paymentStatusDate;
        bookingSlot['planningId'] = planningId;

        body = [bookingSlot];

        return invoke(url, "POST", body, cancel);
    },

    updatePlannerBooking: async (plannerAppId, seminarAttendeeId, plannerCalId, bookingStatusId, bookingStatusDate, updatedBy) => {
        let cancel;
        let url = AoUrl.getPlannerStatusUpdatePath
        let body = {};
        body['plannerAppId'] = parseInt(plannerAppId);
        body['seminarAttendeeId'] = seminarAttendeeId;
        body['plannerCalId'] = plannerCalId;
        body['bookingStatusId'] = bookingStatusId;
        body['bookingStatusDate'] = bookingStatusDate;
        body['updatedBy'] = updatedBy;


        return invoke(url, "POST", body, cancel);
    },
    SendEmailCom: async (emailType, emailTo, emailSubject, emailTemplateId, emailContent, emailStatusId, emailMappingTable, emailMappingTablePKId, createdBy) => {
        let cancel;
        let url = AoUrl.PostEmailCom
        let body = {};
        body['emailType'] = emailType;
        body['emailTo'] = emailTo;
        body['emailSubject'] = emailSubject;
        body['emailTemplateId'] = emailTemplateId;
        body['emailContent'] = emailContent;
        body['emailStatusId'] = emailStatusId;
        body['emailMappingTable'] = emailMappingTable;
        body['emailMappingTablePKId'] = emailMappingTablePKId;
        body['createdBy'] = createdBy;


        return invoke(url, "POST", body, cancel);
    },




    getLoggedInUser: async (userId, appState, loggedInUserId, roleId) => {
        let cancel;
        let url = AoUrl.getAthenticatePath + `${userId}/${appState}/${loggedInUserId}/${roleId}/`;
        let bookingSlot = {};
        return invoke(url, "GET", bookingSlot, cancel);
    },
    getSeminarPublisher: async (subtenantId, isActive) => {
        let cancel;
        let url = AoUrl.getSeminarPublisherPath;
        let body = {};
        body['subtenantId'] = parseInt(subtenantId);
        // body['coordinatorUserId'] = coordinatorUserId;
        body['isActive'] = isActive;
        //konsole.log("bsss",body)
        return invoke(url, "POST", body, cancel);
    },
    getSeminarPublisher1: async (seminarId, isActive) => {
        let cancel;
        let url = AoUrl.getSeminarPublisherPath;
        let body = {};
        body['seminarId'] = parseInt(seminarId);
        body['isActive'] = isActive;

        return invoke(url, "POST", body, cancel);
    },

    postuserorderAddUserOrder: async (adduserobj) => {
        let cancel;
        let url = AoUrl.postAddUserOrder;


        return invoke(url, "POST", adduserobj, cancel)
    },
    getSubtenantSeminarRateCard: async (subtenantRateCardId, isActive) => {
        let cancel;
        let url = AoUrl.getSubtenantRateCardPathSeminar;
        let body = {}
        body['subtenantRateCardId'] = subtenantRateCardId
        // body['skuSubsModelId'] = skuSubsModelId
        // body['rateCardId'] = rateCardId
        // body['subtenantId'] = subtenantId
        // body['skuListId'] = skuListId
        body['isActive'] = isActive
        return invoke(url, "GET", body, cancel);
    },
    postuserorderAddUserOrderCash: async (adduserobjCash) => {
        let cancel;
        let url = AoUrl.postCashPayment;


        return invoke(url, "POST", adduserobjCash, cancel)
    },

    postgetCommMedium: async (occurrenceId, isActive, subtenantId) => {
        let cancel;
        let url = AoUrl.getCommMediumPath;
        let body = {};

        body['occurrenceId'] = occurrenceId;
        body['isActive'] = isActive;
        body['subtenantId'] = subtenantId;

        konsole.log("getCommMediumPath", JSON.stringify(body))

        return invoke(url, 'POST', body, cancel)
    },

    postSendText: async (smsType, textTo, textContent, smsTemplateId, smsStatusId, smsMappingTable, smsMappingTablePKId, createdBy) => {
        let cancel;
        let url = AoUrl.postSendTextPath;
        let body = {}

        body['smsType'] = smsType;
        body['textTo'] = textTo;
        body['textContent'] = textContent;
        body['smsTemplateId'] = smsTemplateId;
        body['smsStatusId'] = smsStatusId;
        body['smsMappingTable'] = smsMappingTable;
        body['smsMappingTablePKId'] = smsMappingTablePKId;
        body['createdBy'] = createdBy;

        konsole.log("postSendTextpostSendText", JSON.stringify(body))


        return invoke(url, 'POST', body, cancel)

    },

    getTextTemplateFunc: async (TempalteId, isActive) => {
        let cancel;
        let url = AoUrl.getTextTemplate + `?TextTemplateId=${TempalteId}&IsActive=${isActive}`;

        return invoke(url, "GET", cancel);
    },
    getSelfCheckingLinks: async (SeminarId, SeminarAttendeeId, SemUserStatusId, LinkType) => {
        let cancel;
        let url = AoUrl.getSelfcheckingLink + `/${SeminarId}/${SeminarAttendeeId}/${SemUserStatusId}/${LinkType}`;

        return invoke(url, "GET", cancel);
    },
    getPlannerClassRoomLinks: async (SeminarId, LinkType) => {
        let cancel;
        let url = AoUrl.getPlannerClassRoomLink + `/${SeminarId}/${LinkType}`;

        return invoke(url, "GET", cancel);
    },




    getConfirmationData: async (SeminarId, isActive) => {
        let cancel;
        let url = AoUrl.getConfirmationListPath
        let body = {};
        body['SeminarId'] = SeminarId;
        body['isActive'] = isActive;

        konsole.log("bny", body)
        return invoke(url, "POST", body, cancel);
    },
    upsertBulkRegistration: async (bulkUpload) => {
        let cancel;
        let url = AoUrl.postBulkRegistration
        let body = bulkUpload;

        return invoke(url, "POST", body, cancel);
    },
    SendLinkToAttendee: async (seminarId, linkType) => {
        let cancel;
        let url = AoUrl.SendLinkToAttendee + `${seminarId}/${linkType}`

        return invoke(url, "POST", {}, cancel);
    },

    postaddressbyuserid: async (data) => {
        let cancel;
        let url = AoUrl.postregisteraddress
        return invoke(url, "POST", data, cancel)

    },
    getaddressbyuserid: async (userid) => {
        let cancel;
        let body = {};
        let url = AoUrl.getregaddressbyuserid + userid
        return invoke(url, "GET", body, cancel)
    },
    getContactDetailsByUserId: async (userId) => {
        let cancel;
        let url = AoUrl.getContactDetails + userId
        let body = {};
        return invoke(url, "GET", body, cancel)

    },
    getClassRoomBooking: async (SeminarId, isActive, ClassroomId, BookingId, SeminarAttendeeId, ClassroomAttendeeId) => {
        let cancel;
        let url = AoUrl.getClassRoomBooking + `/${SeminarId}`
        let body = {};
        // body["ClassroomId"] = ClassroomId;
        // body["BookingId"] = BookingId;
        // body["SeminarAttendeeId"] = SeminarAttendeeId;
        // body["ClassroomAttendeeId"] = ClassroomAttendeeId;
        body["isActive"] = isActive;

        return invoke(url, "GET", body, cancel)

    },

    putMemberDetails: async (subtenantId, fName, lName, userId, updatedBy) => {
        let cancel;
        let url = AoUrl.putAPIForMember;
        let body = {};

        body["subtenantId"] = subtenantId;
        body["fName"] = fName;
        body["lName"] = lName;
        body["userId"] = userId;
        body["updatedBy"] = updatedBy;

        return invoke(url, 'PUT', body, cancel)

    },

    putContactDetails: async (userId, activityTypeId, contactTypeId, mobileNo, contactId, updatedBy) => {
        let cancel;
        let url = AoUrl.putAPIForContact + userId

        let body = {};
        let contact = {};
        let mobiles = {}

        body["userId"] = userId;
        body["activityTypeId"] = activityTypeId
        mobiles["contactTypeId"] = contactTypeId
        mobiles["mobileNo"] = mobileNo
        mobiles["contactId"] = contactId
        mobiles["updatedBy"] = updatedBy

        contact["mobiles"] = [mobiles]
        body["contact"] = contact

        return invoke(url, 'PUT', body, cancel)
    },
    putEmailDetails: async (currentUserName, newUserName, updatedBy, remarks, clientIPAddress) => {
        let cancel;
        let url = AoUrl.updateUserEmail

        let body = {};
        body["currentUserName"] = currentUserName;
        body["newUserName"] = newUserName;
        body["updatedBy"] = updatedBy;
        body["remarks"] = remarks;
        body["clientIPAddress"] = clientIPAddress;
        // //konsole.log("big",body)

        return invoke(url, 'PUT', body, cancel)
    },
    putClassBooking: async (bookingId, isActive, updatedBy, remarks, classroomAttendeeId) => {
        let cancel;
        let url = AoUrl.putClassRoomBooking
        let body = {};
        body["bookingId"] = bookingId;
        body["isActive"] = isActive;
        body["updatedBy"] = updatedBy;
        body["remarks"] = remarks;
        body["classroomAttendeeId"] = classroomAttendeeId;

        //    //konsole.log("haat",body)
        return invoke(url, 'PUT', body, cancel)
    },
    putUserActivate1: async (seminarAttendeeId) => {
        let cancel;
        let url = AoUrl.userActivate11 + `?SeminarAttendeeId=${seminarAttendeeId}`


        let body = {};
        // body["seminarAttendeeId"] = seminarAttendeeId;

        //konsole.log("haat",body)
        return invoke(url, 'PUT', body, cancel)
    },
    putPaymentClassBookingStatus: async (bookingId, isActive, updatedBy, remarks, paymentTrxnNo, paymentStatusId, bookingStatusId) => {
        let cancel;
        let url = AoUrl.putPaymentBookingStatus
        let body = {};
        body["bookingId"] = bookingId;
        body["isActive"] = isActive;
        body["updatedBy"] = updatedBy;
        body["remarks"] = remarks;
        body["paymentTrxnNo"] = paymentTrxnNo;
        body["paymentStatusId"] = paymentStatusId;
        body["bookingStatusId"] = bookingStatusId;

        //konsole.log("haat",body)
        return invoke(url, 'PUT', body, cancel)
    },



    postuserRoles: async (userId, loginUserId, subtenantId, roleId, isActive, createdBy) => {
        let cancel;
        let url = AoUrl.adduserRoles + "/" + userId
        let body = {};
        body["loginUserId"] = loginUserId;
        body["subtenantId"] = subtenantId;
        body["roleId"] = roleId;
        body["isActive"] = isActive;
        body["createdBy"] = createdBy;

        return invoke(url, 'POST', body, cancel)
    },
    postClassRoomRegister: async (userId, seminarAttendeeId, seminarId, classroomId, createdBy) => {
        let cancel;
        let url = AoUrl.postClassRoomBooking


        let body = {};
        body["userId"] = userId;
        body["seminarAttendeeId"] = seminarAttendeeId;
        body["seminarId"] = seminarId;
        body["classroomId"] = classroomId;
        body["createdBy"] = createdBy;

        return invoke(url, 'POST', body, cancel)
    },

    upsertSeminarAttendeesDetails: async (data) => {
        let cancel;
        let url = AoUrl.upsertSeminarAttendeeAPI;
        // let body = {};
        // let attendees = {};

        // body['seminarId'] = seminarId
        // attendees['seminarAttendeeId'] = seminarAttendeeId
        // attendees['attendeeUserId'] = attendeeUserId
        // attendees['semUserStatusId'] = semUserStatusId
        // attendees['userGuestsNos'] = userGuestsNos
        // attendees['referenceMode'] = referenceMode
        // attendees['isActive'] = isActive
        // attendees['upsertedBy'] = upsertedBy

        // body['attendees'] = [attendees]
        return invoke(url, 'POST', data, cancel)


    },

    putAddressDetails: async (addressLine1, zipcode, city, state, country, addressTypeId, addressId, updatedBy, isActive) => {
        let cancel;
        let url = AoUrl.putAPIForAddress;
        let body = {}

        body['addressLine1'] = addressLine1
        body['zipcode'] = zipcode
        // body['county'] = county
        body['city'] = city
        body['state'] = state
        body['country'] = country
        body['addressTypeId'] = addressTypeId
        body['addressId'] = addressId
        body['updatedBy'] = updatedBy
        body['isActive'] = isActive

        return invoke(url, 'PUT', body, cancel)

    },
    getImageBase64: async (fileId) => {
        let cancel;
        let url = AoUrl.getImageBase64 + `${fileId}/1`;
        let body = {};
        return invoke(url, 'GET', body, cancel)

    },
    getUserDetailsByUserEmailId: async ({ userId }) => {
        let url = AoUrl.getUserDetailsByUserEmailId + `?UserId=${userId}`
        let cancel;
        return invoke(url, 'GET', '', cancel)
    },

    initMapScript: () => {
        if (window.google) {
            return Promise.resolve();
        }
        const src = `${mapApi}?key=${mapKey}&libraries=places&v=weekly`;
        return loadAsyncScript(src);
    },
    InvokeCommonApiForLeadStack: function (jsonObj, callback) {
        let token = '4e138444-4400-40f7-bb5c-2588d1b15910'
        let method = 'POST'
        let url = 'https://rest.gohighlevel.com/v1/contacts/'
        axios({
            headers: { 'Access-Control-Allow-Origin': true, "Authorization": `Bearer ${token}` },
            config: { headers: { 'Content-Type': 'application/json' } },
            method: method,
            url: url,
            data: jsonObj
        })
            .then(data => {
                if (callback) {
                    konsole.log("URL-", url, data);
                    callback(data, null);
                }
            })
            .catch(error => {
                let errordata = null;
                konsole.log("Error ", error + url)
                konsole.log("Error ", error.response)
                if (error.response) {
                    errordata = error.response;
                    if ((errordata.status == 400 || errordata.status == 404) && callback) {
                        return callback(null, errordata);

                    }
                    if (errordata.status !== 401 && errordata.status == 503) {
                        konsole.log("Something went Wrong. Try again later.");
                    }
                    if (callback) {
                        return callback(null, errordata);
                    }
                }
            });
    },


}
const loadAsyncScript = async (src) => {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        Object.assign(script, {
            type: "text/javascript",
            async: true,
            src,
        });
        script.addEventListener("load", () => resolve(script));
        document.head.appendChild(script);
    });
};

export default Services;
