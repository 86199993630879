// import Vanue from "../SelfPlanner/Vanu/Vanue";
// import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import { useEffect } from "react";
import '../../Headers/header.css';
import { useHistory } from "react-router-dom";
import { checkIn } from "../../control/Constant";
import LayoutComponent from "../../LayoutComponent";
import konsole from "../../network/konsole"
import Services from "../../network/Services";
import commonLib from "../../control/commonLib";
import { UserContext } from "../../../App";
import { CounterCheckIn, Registered } from "../../control/Constant";
import ConvertToExcel from "../../ConvertToExcel";
import { First } from "react-bootstrap/esm/PageItem";
import BulkRegistration from '../../Bulk/BulkRegistration';
import SearchBar from "../../SearchBar";
import PreviewMail from "../../PreviewMail";
import config from '../../config.json';
import Notification from "../../Notification";


const ClassDashBord = (props) => {


  const history = useHistory();
  const [exclee, setExclee] = useState([])
  const [allUsers, setUserUsers] = useState(null)
  const Location = commonLib.getObjFromStorage("Location");
  const { setLoader } = useContext(UserContext);
  const [counterData, setCounterData] = useState([]);
  const [counterPlannerList, setCounterPlannerList] = useState([]);
  const [selectedAttendee, setSelectedAttendee] = useState();
  const [query, setquery] = useState("");
  const seminarObj = commonLib.getObjFromStorage("seminarObj");
  const venueObj = commonLib.getObjFromStorage("venueObj");
  const subtenantId = commonLib.getSessionStoarge('subtenantId');
  const [clientIpAddress, setClientIpAddress] = useState('');
  const [referenceMode, setReferenceMode] = useState([])
  const [UserNo, setUserNo] = useState()
  const [openSendEmailModal, setopenSendEmailModal] = useState(false)
  const [TextTemplateRes, setTextTemplateRes] = useState(null)
  const [EmailTemplateRes, setEmailTemplateRes] = useState(null)
  const [uniqueNumber, setuniqueNumber] = useState(null)
  const [commChannelId, setcommChannelId] = useState(null)
  const [errors, setErrors] = useState("")
  
  const [showModal1 , setShowModal1] = useState(false)
  const [warning, setWarning] = useState()
  const [Color1, setColor1] = useState("")
  

    const headings = ["S. No","First name","Last name","Email","Phone","Attendees","Lead From","Check-In Status"]
    konsole.log("count2",UserNo)

    useEffect(() => {
    getCounterPlannerList(checkIn);
    getSeminarPublish(745, true, "UPCOMING");
    getIpAddress()
    let promise1 = Services.getReferenceMode();
    promise1.then(res => {
      konsole.log("reference mode", res);
      setReferenceMode(res.data.data)
      counterCheckInList(res.data.data);
    })
  }, []);
  const getIpAddress = () => {
    let promise = Services.getIpAddress();
    promise.then(res => {
      konsole.log("IP address", res);
      setClientIpAddress(res.IPv4);
    })
    
    
  }
  const counterCheckInList = (leadFormData) => {
    setLoader(true);
    const promise = Services.getSeminarUserList(checkIn, seminarObj.subtenantId, seminarObj.seminarId);
    promise.then((res) => {
      setLoader(false);
      konsole.log("konsole", res.data.data[0]?.noOfSeatsBooked
      )
    
      let counterResFilter = res.data.data.filter((res) => {
        return (res.seminarId == seminarObj.seminarId)
      })
      // konsole.log("semUserStatusId11", counterResFilter);
      // for(let i=0;i < counterResFilter[0]?.attendees.length;i++){
      
       
      //   let num = counterResFilter[0]?.attendees[i].seatNos.split(",");
       
      //   konsole.log("sum",num)
        
      // }
      setUserNo(res.data.data[0]?.noOfSeatsBooked)

      const Array = counterResFilter[0].attendees.map((res,index) => {
        return {
          S_No :index+1,
          firstName: res.fName,
          lastName :res.lName,
          EmailId :res.emailId,
          Mobile_Number :commonLib.formatPhoneNumber(res?.mobileno?.slice(-10)),
          Attendees :res.userGuestsNos,
          Lead_From: callReferenceValueApi1(leadFormData ,res?.leadFrom)[0]?.label,
          // Seat_Number :res.seatNos,
          Check_In :res.semUserStatusId == 4 || res.semUserStatusId == 5 ? 'Yes' : 'No'
        }
      })
       setExclee(Array)

      setCounterPlannerList(counterResFilter[0])

    }).catch((err) => {
      konsole.log(`error `, err.response)
    }).finally(error => {
      konsole.log("finish");
      setLoader(false);
    })
  }
  const handelClose =(data)=>{
    konsole.log("datatta",data)
    setopenSendEmailModal(data)
   
    
  }
  const GetEmailTemplateUsers = (TempalteId,isActive)=>{
    setLoader(true)
    const promises = Services.GetEmailTemplateUser(TempalteId,isActive);
      promises.then(res => {
        setLoader(false)
      
      let data2 = res.data.data[0]
     
      setEmailTemplateRes(data2)
   
          setopenSendEmailModal(true)
     
      
    })
  
    .catch(err => {
      konsole.log("err", err.response);
      setLoader(false)
      setWarning("Please set your template in CMS.")
      setColor1("#ffcc00")
      setShowModal1(true)
      
    }).finally(fin => {
      // konsole.log("finish");
      setLoader(false);
    })

  }
  const GetTextTemplateUsers1 = (TempalteId,isActive,unique)=>{
  //  //alert("hhh")
    const promises = Services.getTextTemplateFunc(TempalteId,isActive);

    konsole.log("restR11111", TempalteId,isActive);
    promises.then(res => {
        konsole.log("ihkjgiu",TempalteId)
      let data = res.data.data[0]?.textTemplateContent
      let data2 = res.data.data[0]
      setTextTemplateRes(data2)
      konsole.log("restapi1112",data2)
      
      
      setopenSendEmailModal(true)
    
      
    })
  
    .catch(err => {
      konsole.log("err", err.response);
      setWarning("Please set your template in CMS.")
      setColor1("#ffcc00")
      setShowModal1(true)
      
    }).finally(fin => {
      // konsole.log("finish");
      setLoader(false);
    })

  }

   const occurrenceData =(occurrenceId,isActive,subtenantId,uniqueNumber)=>{
      setLoader(true
    )
    const promise = Services.postgetCommMedium(occurrenceId, isActive, subtenantId);
    konsole.log("rest11", occurrenceId, isActive, subtenantId,uniqueNumber)
    promise.then((res) => {
        setLoader(false)
        let textId = res.data.data[0]?.applicableTextTempId;
         let commChannelId = res?.data?.data[0]?.commChannelId
        setcommChannelId(commChannelId)
        let applicableEmailTempId = res.data.data[0]?.applicableEmailTempId
        if(commChannelId == 1){
          //alert("OnlyEmail")
          GetEmailTemplateUsers(applicableEmailTempId,true,uniqueNumber)
          
        }
      else if(commChannelId == 2){
        //alert("Only Text")
        GetTextTemplateUsers1(textId,true,uniqueNumber)
    }
    else if(commChannelId == 3){
      //alert("Both")
     GetTextTemplateUsers1(textId,true,uniqueNumber)
     GetEmailTemplateUsers(applicableEmailTempId,true,uniqueNumber)

    }
    
      
   
     
  
  
  }).catch((err) => {
   
  konsole.log(`errorw `, err.response.data)

 

  // if(err.response.status == 404){
    
  //   let subtenantId1 = config.DEFAULT_SUBTENANTID_FOR_OCCURRENCE
    
  //   handelSend(occurrenceId, isActive, subtenantId1,unique) 
    setLoader(false)
    
  // if(err.response.status == 404){
  //   let subtenantId1 = config.DEFAULT_SUBTENANTID_FOR_OCCURRENCE
  //   handelSend(occurrenceId, isActive, subtenantId1,unique) 
    setWarning("Please set your template in CMS.")
    setColor1("#ffcc00")
    setShowModal1(true)
    setLoader(false)
// }
}).finally(error => {
  konsole.log("finish");
  setLoader(false);
})



  }

  const handelSendData =(seminarId,LinkType,uniqueNumber)=>{
  // konsole.log("etryyyry",seminarId,LinkType,uniqueNumber)
  if(errors == "No Data Found"){
    setWarning("Please create workshop as no workshop available!")
    setColor1("#ffcc00")
    setShowModal1(true)
  }else{
    setuniqueNumber(uniqueNumber)
    setLoader(true)
 const promise = Services.getPlannerClassRoomLinks(seminarId,LinkType);
 konsole.log("rest11433", seminarId,LinkType,uniqueNumber)
 promise.then((res) => {
     setLoader(false)
   let user = res?.data?.data
   // setnewUser(user)
   setUserUsers(user)
   let occurrenceId = uniqueNumber === 1 ? 8 : uniqueNumber === 2 ? 3 : 17;
   occurrenceData(occurrenceId,true,subtenantId,uniqueNumber)

 }).catch((err) => {
   konsole.log(`errorw11 `, err.response.data)
   // if(err.response.data.messages[0] === "No CheckIn Attendees Available"){
     setWarning(err.response?.data?.errorFlag)
     setColor1("#ffcc00")
     setShowModal1(true)
   //   // //alert("There are no checked-in users available at this moment. Please check-in the user to send the link.")
   //  }
   setLoader(false)
 }).finally(error => {
   konsole.log("finish");
 //   setLoader(false);
 })
  }
  }
  const getSeminarPublish = (subtenantId1, isActive, seminarType) => {
        const promise = Services.getClassroomPublisherDetail(subtenantId1, isActive, seminarType);
          setLoader(true);
          promise.then(response => {
          setLoader(false)
      
     
          }).catch((e) => {
              
              if(e.response.data.message === "No Data Found"){
                setErrors(e.response.data.message)
                  setLoader(false);
              }
              else{
                  setLoader(false);
                  konsole.log(e)
        
              }
       }).finally(() => {
      // setLoader(false);
  });
}





  const handleAttendeeSelect = (index) => {
    const readmoreStaus = true
    const simpleClass = true
    commonLib.setDatatoSession('readcalssroom',JSON.stringify(readmoreStaus))
    commonLib.setDatatoSession('simpleCalssroom',JSON.stringify(simpleClass))
    const selectedUserObj = counterPlannerList.attendees[index];
    setLoader(true);
   
    const seminarid=counterPlannerList.seminarId
    konsole.log('seminarId',seminarid)
    const selectedUser = {
      attendeeFullName: selectedUserObj.fName + " " + selectedUserObj.lName,
      seminarAttendeeUserId: selectedUserObj.attendeeUserId,
      attendeeEmailAddress: selectedUserObj.emailId,
      attendeeMobileNo: selectedUserObj.mobileno,
      seminarAttendeeId: selectedUserObj.seminarAttendeeId,
      seminarid:seminarid,
      noOfAttendeeise: 1,
      rollId : 2,
      semUserStatusId : selectedUserObj.semUserStatusId,
      counntryCode : "+1",
      clientIpAddress : clientIpAddress,
      seminarid:seminarid,
      firstName:selectedUserObj.fName



    }
    commonLib.setSessionStoarge("attendeeObj",JSON.stringify(selectedUser));
    // konsole.log("fame",selectedUser)
    setLoader(false);
    history.push({
      pathname: '/classroomregistration',
      state: selectedUser
    })

  }




  const getCounterPlannerList = (checkIn) => {
    setLoader(true);
    const promise = Services.getSeminarUserList(checkIn, seminarObj?.subtenantId, seminarObj.seminarId);
    promise.then((res) => {
      setLoader(false);
      let counterResFilter = res.data.data.filter((res) => {
        return (res.seminarId == seminarObj.seminarId)
      })
      setCounterPlannerList(counterResFilter[0])
    }).catch((err) => {
      konsole.log(`error %% ${err}`)
    }).finally(fin => {
      konsole.log("finish");
      setLoader(false);
    })
  }
  const sendClassroomLink = () => {
    const promises = Services.SendLinkToAttendee(seminarObj.seminarId, "CLASSROOM");
    // konsole.log("false",seminarObj.seminarId)
    setLoader(true);
    promises.then((res)=> {
      setLoader(false);
      konsole.log("respnset", res);
    })
    .catch(error => {
      konsole.log("error", error);
    }).finally(()=>{
      setLoader(false);
    })
  }
  const callReferenceValueApi = (IdData) =>{
      
    konsole.log("IdDataIdDataIdData",referenceMode)
    return referenceMode.filter((x)=>x.value == IdData)
  }
  const callReferenceValueApi1 = (leadFormData,IdData) =>{
    konsole.log("IdDataIdDataIdData",referenceMode )
    konsole.log("IdDataIdDataIdData", IdData)
    return (leadFormData !== undefined && leadFormData !== null && leadFormData !== "") ?leadFormData.filter((x)=>x?.value == IdData) : "noData"
  }

  const sentData =(data)=>{
    if(errors == "No Data Found"){
      setWarning("Please create workshop as no workshop available!")
      setColor1("#ffcc00")
      setShowModal1(true)
    }else{
      konsole.log("datat",data)
      setLoader(true);
      let promise = Services.getSelfCheckingLinks(counterPlannerList?.seminarId ,data?.seminarAttendeeId,data?.semUserStatusId ,"CLASSROOM")
      promise.then((response) => {
        konsole.log("samplkesljgdr",response)
        window.location.replace(response?.data?.data?.uniqueURL)
        // history.push({
        //   pathname: response?.data?.data?.uniqueURL,
          // state: selectedUser
        // })
        setLoader(false);
    
      }).catch((error) => {
        konsole.log("getaddressbyuseridError", error)
      }).finally(() => {
    
      })
    }
    
   }
 

  return (
    <LayoutComponent>
      <Notification showModal={showModal1} message={warning} color={Color1} setShowModal={setShowModal1}/>
      <div className="container-fluid bgclr counter-main-div" style={{height:"100vh"}}>
        <div className="pt1 d-flex justify-content-between w-100 mobileLocate">
          <h5>
            <b>Workshop Registration - Counter</b>
          </h5>
           <div className='p-0'>
              <h6>Venue: {Location?.seminarVenue}</h6>
              {
                venueObj?.venueAddressLine1 !== null && venueObj?.venueAddressLine1 !== undefined && venueObj?.venueAddressLine1 !== ""&&
              <p className="bi bi-geo-alt form-label fs-6" style={{ color: "#720c20" }} >Location: {venueObj?.venueAddressLine1}</p>
              }

              <p className=" bi bi-geo-alt form-label fs-6" style={{ color: "#720C20" }} >
               {commonLib.getFormattedDate(seminarObj?.seminarDate)} & {commonLib.getFormattedTime(seminarObj?.seminarTime)}</p>
            </div>
        </div>

        <div className="Main-container-counter bg-white">

          <div className="row px-3 ">
            <h6 className="counter-text mt-2 mobileText1">{seminarObj?.seminarTopic} (Check Ins : {UserNo}/{counterPlannerList?.seatingCapacity})</h6>
           
            <div className="col-sm-12  col-md-5 col-lg-5  me-auto counter-text mt-auto mb-auto">
               <SearchBar setquery={setquery}/>
            </div>
            <div className="col">


            </div>
            
            <div className="col-sm-4 d-flex justify-content-end mt-auto mb-auto" >
            
            <button className='reg d-flex justify-content-center  align-items-center rounded text-white text-center mobile' type='button' id='btnRegister'
                style={{height:"46px",border:"none",fontSize:"14px"}}
                onClick={()=>handelSendData(seminarObj?.seminarId,"CLASSROOM",1)}
              >
                Send Workshop Link
              </button>
          

            
          </div>
          {exclee.length>0?<ConvertToExcel data={exclee} headings={ headings}pName="Classroom.xlsx" />:""}
          </div>
          {/* table */}
          <div className="table-contaier  mt-1 ">
            <table className="table bg-white  gap-1 ;">
              
              <thead style={{position : "sticky",top:-1,zIndex:'5'}}>
                <tr className="bgclr gap-1 text-danger fs-6">
                {headings.map((ele)=>{
                      return (
                        <th className = {ele == "S. No" || ele == "First name" || ele == "Last name" || ele == "Email" || ele == "Phone" ? "" : "text-center"}>{ele}</th>
                     )})}
                </tr>
              </thead>
              <tbody>
                {
                  counterPlannerList.attendees !== undefined && counterPlannerList?.attendees?.length > 0 && counterPlannerList?.attendees?.filter(item => item?.fName?.toLowerCase()?.includes(query) ||
                    item?.lName?.toLowerCase()?.includes(query) ||
                    item?.emailId?.toLowerCase()?.includes(query) ||
                    item?.mobileno?.toLowerCase()?.includes(query))?.map((item, index) => {

                      return (
                        <tr key={index} className="cursor-pointer m-0 p-0">
                          <td className="" style={{height:"10px"}}>{index + 1}</td>
                          <td className="">{item?.fName?.charAt(0)?.toUpperCase() + item?.fName?.slice(1)}<br/><a onClick={()=>sentData(item)} className="mt-2">Self Workshop Link</a></td>
                          <td className=""onClick={() => handleAttendeeSelect(index)}>{item?.lName?.charAt(0)?.toUpperCase() + item?.lName?.slice(1)}</td>
                          <td className=""onClick={() => handleAttendeeSelect(index)}>{item.emailId.split(',')[0]}</td>
                          <td className="">
                          {item.mobileno.slice(0,-10) }
                              { " "}
                               {commonLib.formatPhoneNumber(item.mobileno.slice(-10))}
                          </td>
                          <td className="text-center"onClick={() => handleAttendeeSelect(index)}>{item.userGuestsNos}</td>
                          <td className="text-center"onClick={() => handleAttendeeSelect(index)}>{callReferenceValueApi(item.leadFrom)[0]?.label}</td>
                          <td className="text-center"onClick={() => handleAttendeeSelect(index)}>{(item.semUserStatusId == 4 || item.semUserStatusId == 5) ? 'Yes' : 'No'}</td>
                        </tr>)
                    })
                }
                
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {
        openSendEmailModal == true ? <PreviewMail setopenSendEmailModal={setopenSendEmailModal} cancelMOdal={handelClose} openSendEmailModal={openSendEmailModal}
        titleText="Send Email" TextTemplateRes={TextTemplateRes}allUsers={allUsers}uniqueNumber={uniqueNumber}EmailTemplateRes={EmailTemplateRes}commChannelId={commChannelId} />:"" }
    </LayoutComponent>
  );
};

export default ClassDashBord;
