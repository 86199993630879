import Dymo from "dymojs";
import xmltojson from "xmltojson";
import returnXml from "./xml";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Notification from "../Notification";

function DymoPrinter(props) {
  const { fName, lName } = props.userInfo;
  const [ showmodal, setShowModal ] = useState(false);
  const [showModal1 , setShowModal1] = useState(false)
  const [warning, setWarning] = useState()
  const [Color1, setColor1] = useState("")
  // const qrCodeUrl = "http://localhost:3000/";
  const userFullName = `${fName} ${lName}`;
  const userNameFontSize = (userFullName.length < 15)? 24 : 20;
  // const userEmail = emailId;
  // const emailFontSize = (userEmail?.length <20)? 10: 8; 
  // const userEventName = props?.seminarTopic;
  // const userSeatNo = seatNos;
  // const imageUrl = props.imageURL;
  const isDisabled = props.disabled;
  
  const { labelXml } = returnXml(
                          // qrCodeUrl,
                          // imageUrl ,
                          userFullName,
                          userNameFontSize , 
                          // userEmail,
                          // emailFontSize,
                          // userEventName,
                          // userSeatNo
                          );
  const dymo = new Dymo();
  const [imageData, setImageData] = useState(null);
  const [printerInfo, setPrinterInfo] = useState({});

  //konsole.log("xml show", isDisabled, userFullName, userNameFontSize);

  const getAllPrinter = (actionType) => {
    //konsole.log();
    if (actionType == "Yes"){
      dymo.getPrinters().then((res) => {
        //konsole.log("ress", res);
        const json = xmltojson.parseString(res);
        //konsole.log("json", json);
        const printers = json.Printers;
        if (printers.length > 0) {
          const obj = returnObject(printers[0].LabelWriterPrinter[0]);
          if (obj.IsConnected == true) {
            printDymoLabel(obj);
          } else {
            setWarning("please connect printer")
            setColor1("#ffcc00")
            setShowModal(true)
            // alert("please connect printer");
          }
        }
      });
    }
    else if(actionType == "No"){
      setShowModal(!showmodal)
    }
  };

  const handleShowModal = () => {

    const macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i;
    //konsole.log("macosplatform", !macosPlatforms.test(window.navigator.platform))
    if (!macosPlatforms.test(window.navigator.platform)) {
      dymo.getStatus().then((res) => {
        getView();
        setShowModal(!showmodal)
      }).catch((error) => {
        //konsole.log("error", error.status);
        setWarning("Please install the respective software and SDK or check whether the printer is connected properly.")
        setColor1("#FA113D")
        setShowModal1(true)
        // alert("Please install the respective software and SDK or check whether the printer is connected properly.")
      });
    }
    else {
      setWarning("Dymo SDK does not support the MacOS. Please use WindowOS.")
      setColor1("#ffcc00")
      setShowModal1(true)
      // alert("Dymo SDK does not support the MacOS. Please use WindowOS.")
      setShowModal(false)
    }



  }

  const returnObject = (object) => {
    let obj = {};
    for (let [key, value] of Object.entries(object)) {
      //konsole.log("printkeyvalue", key, value);
      obj[`${key}`] = value[0]._text;
    }
    //konsole.log("object", obj);
    return obj;
  };

  const getView = () => {
    dymo.renderLabel(labelXml).then((imageData) => {
      //konsole.log("string", imageData);
      const string = `data:image/png;base64,`.concat(eval(imageData));
      setImageData(string);
      // //konsole.log("print image ", `data:image/png;base64,${eval(imageData)}`)
      // var a = document.createElement("a"); //Create <a>
      // a.href =  string//Image Base64 Goes here
      // a.download = "Image.png"; //File name Here
      // a.click(); //Downloaded file
    });
  };

  const printDymoLabel = (printerInfo) => {
    //konsole.log("printer", Object.keys(printerInfo));
    if (Object.keys(printerInfo).legnth == 0) {
      // alert("No printer found");
      setWarning("No printer found.")
      setColor1("#ffcc00")
      setShowModal1(true)
      return;
    }
    dymo.print(printerInfo.Name, labelXml);
    setShowModal(false);
  };

  return (
    <>
    <Notification showModal={showModal1} message={warning} color={Color1} setShowModal={setShowModal1}/>
    <div className="App ms-3">
      <img src="/icons/printerIcon.svg" onClick={()=> {(!isDisabled) && handleShowModal()}}/>

      <Modal show={showmodal} >
        <Modal.Header className="border-0" >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="border-0" >
          <p className="text-center fs-5" style={{color: "#720c20", fontWeight: '700'}}>Do you want to print the label?</p>
            <div className="text-center">
              {imageData !== null && 
                <img src={imageData} width="100%" height="200px" className="labelImage" alt="label display"/>
              }
            </div>
        </Modal.Body>
        <Modal.Footer className="border-0" >
            <Button className='w-25 ' style={{ backgroundColor: '#d3d3d3', color: "#720C20" }} onClick={() => getAllPrinter('No')}>
              No
            </Button>
            <Button className="w-25" style={{ backgroundColor: '#720C20' }} onClick={() => getAllPrinter('Yes')} >
              Yes
            </Button>
        </Modal.Footer>
      </Modal>




    </div>
    
    </>
  );
}

export default DymoPrinter;
